import React from "react"
import dayjs from "dayjs"
import { Color, FontFormat } from "../../util"
import { FlexContainer, H2, P } from "../../style/main"
import { Container, StatusBox, CardContainer, ProductImage } from "./styled"

export const TicketCard = ({ ticket }) => {
  const textColor = (ticket) => {
    switch (ticket) {
      case "Pending":
        return "#F4C900"
      case "In Progress":
        return "#007D64"
      case "Supporter":
      case "Main":
        return Color.Primary
      case "Closed":
        return Color.Error
      default:
        return Color.Primary
    }
  }
  const background = (ticket) => {
    switch (ticket) {
      case "Pending":
        return "#FEFBE4"
      case "In Progress":
        return "#E0F3F0"
      case "Supporter":
        return Color.Secondary
      case "Main":
        return Color.Baby_Blue
      case "Closed":
        return "#F9E5EB"
      default:
        return Color.Secondary
    }
  }
  const formattedDate = dayjs(ticket.updated_at).format("D MMM YYYY, h.mm A")
  return (
    <Container to={`/tickets/detail/${ticket.id}`}>
      <CardContainer>
        <FlexContainer
          $alignItems={"flex-start"}
          $justifyContent={"space-between"}
          $width={"100%"}
        >
          <ProductImage
            src={ticket.product.thumbnail_image}
            alt={ticket.product.name}
          />
          <FlexContainer $flexDirection={"column"} $alignItems={"flex-end"}>
            <P $FontFormat={FontFormat.Title_SM} $textColor={Color.Disabled}>
              Case ID: {ticket.ticket_number}
            </P>
            <StatusBox
              $textColor={textColor(ticket.status)}
              $background={background(ticket.status)}
            >
              {ticket && ticket.status}
            </StatusBox>
          </FlexContainer>
        </FlexContainer>
        <div>
          <H2
            $FontFormat={FontFormat.Title_MD}
            $textColor={Color.Tertiary}
            $overFlow={"hidden"}
            $textOverflow={"ellipsis"}
            $display={"-webkit-box"}
            $webkitLineClamp={2}
            $webkitBoxOrient={"vertical"}
          >
            Subject: {ticket.title}
          </H2>
          <P $FontFormat={FontFormat.Body_SM} $textColor={Color.Tertiary}>
            Product: {ticket.product.name}
          </P>
        </div>
        <div>
          <P $FontFormat={FontFormat.Body_SM} $textColor={Color.Tertiary}>
            Supervisor:{" "}
            {ticket.user_ticket.name ? ticket.user_ticket.name : "-"}
          </P>
          <P
            $FontFormat={FontFormat.Label_MD}
            $textColor={Color.Tertiary_Hover}
          >
            Updated: {formattedDate}
          </P>
        </div>
      </CardContainer>
    </Container>
  )
}
