import styled from "styled-components"
import { Link } from "react-router-dom"
import { Color } from "../../util"

export const Container = styled(Link)`
  display: flex;
  justify-content: center;
  width: 33.3333333333%;
  text-decoration: unset;
  @media screen and (max-width: 1300px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const StatusBox = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  color: ${(props) => props.$textColor};
  border: 1px solid;
  background: ${(props) => props.$background};
  white-space: nowrap;
`
export const CardContainer = styled.div`
  display: flex;
  width: 350px;
  padding: 24px 16px;
  margin-bottom: 56px;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  border-radius: 28px;
  background-color: ${Color.Secondary};
  box-shadow: 0px 4px 4px 0px ${Color.Baby_Blue};
`
export const ProductImage = styled.img`
  width: 95px;
  height: 85px;
  border-radius: 16px;
  object-fit: cover;
  object-position: center;
`
