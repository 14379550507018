import styled from "styled-components"
import { Color } from "../util"

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection};
  flex-wrap: ${(props) => props.$flexWrap};
  justify-content: ${(props) => props.$justifyContent};
  align-items: ${(props) => props.$alignItems};
  gap: ${(props) => props.$gap};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  min-height: ${(props) => props.$minHeight};
  margin: ${(props) => props.$margin};
  padding: ${(props) => props.$padding};
  overflow: ${(props) => props.$overflow};
`
export const OverlayContainer = styled.div`
  position: fixed;
  top: ${(props) => props.$top && `blur(3px)`};
  left: ${(props) => props.$left && `blur(3px)`};
  width: 100%;
  height: 100%;
  z-index: 999;
  backdrop-filter: ${(props) => props.$isBlur && `blur(3px)`};
  background: ${(props) => props.$isBlur && `rgba(0, 0, 0, 0.25)`};
`
export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  height: auto;
  padding: ${(props) => props.$padding};
  border-radius: 16px;
  background-color: ${Color.Secondary};
  gap: ${(props) => props.$gap};
  @media screen and (max-width: 600px) {
    width: 70%;
    overflow: hidden;
  }
`
export const P = styled.p`
  ${(props) => props.$FontFormat};
  color: ${(props) => props.$textColor};
  padding: ${(props) => props.$padding};
  margin: ${(props) => props.$margin};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  text-align: ${(props) => props.$textAlign};
  white-space: ${(props) => props.$whiteSpace};
  overflow: ${(props) => props.$overFlow};
  text-overflow: ${(props) => props.$textOverflow};
  display: ${(props) => props.$display};
  -webkit-line-clamp: ${(props) => props.$webkitLineClamp};
  -webkit-box-orient: ${(props) => props.$webkitBoxOrient};
`
export const Span = styled.span`
  ${(props) => props.$FontFormat};
  color: ${(props) => props.$textColor};
  padding: ${(props) => props.$padding};
  margin: ${(props) => props.$margin};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  text-align: ${(props) => props.$textAlign};
  white-space: ${(props) => props.$whiteSpace};
  overflow: ${(props) => props.$overFlow};
  text-overflow: ${(props) => props.$textOverflow};
  display: ${(props) => props.$display};
  -webkit-line-clamp: ${(props) => props.$webkitLineClamp};
  -webkit-box-orient: ${(props) => props.$webkitBoxOrient};
`
export const H1 = styled.h1`
  ${(props) => props.$FontFormat};
  color: ${(props) => props.$textColor};
  padding: ${(props) => props.$padding};
  margin: ${(props) => props.$margin};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  text-align: ${(props) => props.$textAlign};
  white-space: ${(props) => props.$whiteSpace};
  overflow: ${(props) => props.$overFlow};
  text-overflow: ${(props) => props.$textOverflow};
  display: ${(props) => props.$display};
  -webkit-line-clamp: ${(props) => props.$webkitLineClamp};
  -webkit-box-orient: ${(props) => props.$webkitBoxOrient};
`
export const H2 = styled.h2`
  ${(props) => props.$FontFormat};
  color: ${(props) => props.$textColor};
  padding: ${(props) => props.$padding};
  margin: ${(props) => props.$margin};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  text-align: ${(props) => props.$textAlign};
  white-space: ${(props) => props.$whiteSpace};
  overflow: ${(props) => props.$overFlow};
  text-overflow: ${(props) => props.$textOverflow};
  display: ${(props) => props.$display};
  -webkit-line-clamp: ${(props) => props.$webkitLineClamp};
  -webkit-box-orient: ${(props) => props.$webkitBoxOrient};
`
export const H3 = styled.h3`
  ${(props) => props.$FontFormat};
  color: ${(props) => props.$textColor};
  padding: ${(props) => props.$padding};
  margin: ${(props) => props.$margin};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  text-align: ${(props) => props.$textAlign};
  white-space: ${(props) => props.$whiteSpace};
  overflow: ${(props) => props.$overFlow};
  text-overflow: ${(props) => props.$textOverflow};
  display: ${(props) => props.$display};
  -webkit-line-clamp: ${(props) => props.$webkitLineClamp};
  -webkit-box-orient: ${(props) => props.$webkitBoxOrient};
`
export const H4 = styled.h4`
  ${(props) => props.$FontFormat};
  color: ${(props) => props.$textColor};
  padding: ${(props) => props.$padding};
  margin: ${(props) => props.$margin};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  text-align: ${(props) => props.$textAlign};
  white-space: ${(props) => props.$whiteSpace};
  overflow: ${(props) => props.$overFlow};
  text-overflow: ${(props) => props.$textOverflow};
  display: ${(props) => props.$display};
  -webkit-line-clamp: ${(props) => props.$webkitLineClamp};
  -webkit-box-orient: ${(props) => props.$webkitBoxOrient};
`
export const Radio = styled.input`
  margin: 0;
  width: 24px;
  height: 24px;
`
export const Checkbox = styled.input`
  margin: 0;
  width: 24px;
  height: 24px;
`
