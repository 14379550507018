import styled from "styled-components"
import { Link } from "react-router-dom"
import { Color, FontFormat } from "../../util"

export const Logo = styled.img`
  width: 190px;
  height: 107px;
  @media screen and (max-width: 657px) {
    width: 160px;
  }
`
export const MenuLink = styled(Link)`
  font-size: ${FontFormat.Title_XL.fontSize};
  font-weight: ${FontFormat.Title_XL.fontWeight};
  color: ${Color.Tertiary};
  text-decoration: ${(props) => props.$textDecoration};
  text-decoration-color: ${Color.Primary};
  text-decoration-thickness: 5px;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${Color.Primary};
    text-decoration-thickness: 5px;
  }
  @media screen and (max-width: 600px) {
    font-size: ${FontFormat.Title_MD.fontSize};
    font-weight: ${FontFormat.Title_MD.fontWeight};
  }
  @media screen and (max-width: 480px) {
    font-size: ${FontFormat.Title_SM.fontSize};
    font-weight: ${FontFormat.Title_SM.fontWeight};
  }
`
export const MenuHamburger = styled(Link)`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  color: ${Color.Secondary};
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    color: ${Color.Secondary_Hover};
  }
  @media screen and (max-width: 480px) {
    font-size: ${FontFormat.Body_SM.fontSize};
    font-weight: ${FontFormat.Body_SM.fontWeight};
  }
`
export const MenuHamburgerLogout = styled.button`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  color: ${Color.Secondary};
  background: none;
  border: unset;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    color: ${Color.Secondary_Hover};
  }
  @media screen and (max-width: 480px) {
    font-size: ${FontFormat.Body_SM.fontSize};
    font-weight: ${FontFormat.Body_SM.fontWeight};
  }
`
export const LogoutButton = styled.button`
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  color: ${Color.Primary};
  text-align: center;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${Color.Primary};
  background-color: ${Color.Secondary};
  cursor: pointer;
  &:hover {
    color: ${Color.Primary_Hover};
    background-color: ${Color.Secondary_Hover};
  }
  &:active,
  &:focus {
    color: ${Color.Secondary};
    background-color: ${Color.Secondary_Pressed};
  }
`
export const BackgroundBlur = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
`
export const CircleIconCntainer = styled.div`
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: ${Color.Primary};
`
