import React from "react"
import CircularProgress from "@mui/material/CircularProgress"
import { Color, FontFormat } from "../../util"
import { FlexContainer, H2 } from "../../style/main"

export const Loading = () => {
  return (
    <FlexContainer
      $flexDirection={"column"}
      $alignItems={"center"}
      $justifyContent={"center"}
      $height={"100vh"}
      style={{ backgroundColor: Color.Pastel_Blue }}
    >
      <H2 $FontFormat={FontFormat.Title_XL}>Loading</H2>
      <CircularProgress
        size={24}
        style={{
          color: Color.Primary,
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      />
    </FlexContainer>
  )
}
