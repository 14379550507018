import { firebaseConfig } from "../services/auth/firebaseConfig"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getClientUserByFirebaseId } from "../api"

const auth = getAuth(firebaseConfig)

export const userInfo = async () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const newToken = await user.getIdToken()
          localStorage.setItem("accessToken", newToken)
          const clientUser = await getClientUserByFirebaseId(user.uid)
          let userInfo = clientUser.length > 0 && clientUser[0]

          resolve(userInfo)
        } catch (error) {
          reject(error)
        }
      } else {
        resolve(false)
      }
    })
  })
}
