import React from "react"
import {
  Error,
  Login,
  ForgetPassword,
  ResetPassword,
  Profile,
  CreateProfile,
  UpdateProfile,
  Tickets,
  TicketCreate,
  TicketsDetail,
} from "../views"
import { Routes, Route, Navigate } from "react-router-dom"
import { PrivateRoute } from "./PrivateRoute"

export const MainRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Error />} />
      <Route path="/" element={<Navigate to="/tickets" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/clientUser/create/:verifyId" element={<CreateProfile />} />
      <Route
        path="/clientUser/update/:clientId"
        element={
          <PrivateRoute>
            <UpdateProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets"
        element={
          <PrivateRoute>
            <Tickets />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets/create"
        element={
          <PrivateRoute>
            <TicketCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets/detail/:ticketId"
        element={
          <PrivateRoute>
            <TicketsDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
    </Routes>
  )
}
