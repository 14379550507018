// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-toolbar.ql-snow {
    border: unset;
    background-color: #DBF4FE;
}
.ql-container.ql-snow {
    border: unset;
}
.ql-editor {
    height: 237px;
    font-size: 18px;
    font-weight: 400;
    font-family: "IBM Plex Sans Thai", sans-serif;
}

.react-multiple-carousel__arrow {
    z-index: 1 !important;
}
.ticket-message-file .react-multi-carousel-track {
    flex-direction: row-reverse;
}
.supporter .react-multi-carousel-track {
    flex-direction: row;
}
.ticket-message-file .react-multiple-carousel__arrow--left {
    left: -25px;
}
.ticket-message-file .react-multiple-carousel__arrow--right {
    right: -25px;
}
.ticket-message-file .react-multi-carousel-item{
    align-items: center;
}
.ticket-message-file .react-multiple-carousel__arrow,
.ticket-message-file .react-multiple-carousel__arrow:hover {
    background: unset !important;
}
@media only screen and (max-width: 600px) {
    .ticket-message-file .react-multiple-carousel__arrow--left,
    .ticket-message-file .react-multiple-carousel__arrow--right {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/ticketsDetail/styled.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,6CAA6C;AACjD;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;AACA;IACI,mBAAmB;AACvB;AACA;;IAEI,4BAA4B;AAChC;AACA;IACI;;QAEI,aAAa;IACjB;AACJ","sourcesContent":[".ql-toolbar.ql-snow {\n    border: unset;\n    background-color: #DBF4FE;\n}\n.ql-container.ql-snow {\n    border: unset;\n}\n.ql-editor {\n    height: 237px;\n    font-size: 18px;\n    font-weight: 400;\n    font-family: \"IBM Plex Sans Thai\", sans-serif;\n}\n\n.react-multiple-carousel__arrow {\n    z-index: 1 !important;\n}\n.ticket-message-file .react-multi-carousel-track {\n    flex-direction: row-reverse;\n}\n.supporter .react-multi-carousel-track {\n    flex-direction: row;\n}\n.ticket-message-file .react-multiple-carousel__arrow--left {\n    left: -25px;\n}\n.ticket-message-file .react-multiple-carousel__arrow--right {\n    right: -25px;\n}\n.ticket-message-file .react-multi-carousel-item{\n    align-items: center;\n}\n.ticket-message-file .react-multiple-carousel__arrow,\n.ticket-message-file .react-multiple-carousel__arrow:hover {\n    background: unset !important;\n}\n@media only screen and (max-width: 600px) {\n    .ticket-message-file .react-multiple-carousel__arrow--left,\n    .ticket-message-file .react-multiple-carousel__arrow--right {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
