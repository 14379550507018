import styled from "styled-components"
import { Color, FontFormat } from "../../../util"
import { background_create_user_page } from "../../../img"

export const BackGroundImageContainer = styled.div`
  width: 100%;
  background-image: url(${background_create_user_page});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: left;
  background-color: ${Color.Primary};
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 768px) {
    width: 100%;
    background-size: cover;
    background-position: center;
  }
  @media (min-width: 1921px) {
    justify-content: center;
  }
`
export const BlueBoxContainer = styled.div`
  width: 70%;
  height: ${(props) => props.$height}px;
  min-height: 100vh;
  padding: 16px 93px 16px 20px;
  background-color: ${Color.Baby_Blue};
  float: right;
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: 1200px) {
    width: 85%;
    margin: 16px;
    padding: 32px;
    overflow-x: hidden;
  }
  @media (max-width: 1024px) {
    width: 100%;
    margin: 16px;
    padding: 32px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  @media (max-width: 480px) {
    height: 90%;
    margin: 16px;
    padding: 32px 16px;
    float: unset;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  @media (min-width: 1921px) {
    width: 1400px;
    height: 40%;
    margin: 16px;
    padding: 32px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`
export const Vector = styled.img`
  width: 90%;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 480px) {
    width: 80%;
  }
  @media (min-width: 1921px) {
    width: unset;
  }
`
export const VectorAccountIsAlready = styled.img`
  width: auto;
  @media (max-width: 996px) {
    width: 80%;
  }
  @media (max-width: 400px) {
    width: 300px;
  }
`
export const InputContainer = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }
`
export const Description = styled.textarea`
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  height: 120px;
  padding: 12px;
  border-radius: 6px;
  color: ${Color.Primary};
  border: 1px solid ${(props) => props.$borderColor};
  background-color: ${Color.Secondary};
  &:active,
  &:focus {
    border: 1px solid ${Color.Primary_Pressed};
  }
  &:focus-visible {
    outline-color: ${Color.Primary};
  }
`
export const ButtonUploadImage = styled.button`
  width: 386px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  padding: 8px;
  border-radius: 16px;
  border: 1px solid
    ${(props) => (props.$isValid ? Color.Error : Color.Disabled)};
  background-color: ${Color.Secondary};
  cursor: pointer;
  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
  }
  &:hover {
    background-color: ${Color.Secondary_Hover};
  }
  &:active,
  &:focus {
    background-color: ${Color.Secondary};
  }
`
export const ButtonCreateContainer = styled.div`
  width: 168px;
  height: 48px;
  position: relative;
  cursor: pointer;
`
export const ButtonCreate = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  cursor: pointer;
  font-size: ${FontFormat.Title_SM.fontSize};
  font-weight: ${FontFormat.Title_SM.fontWeight};
  width: 168px;
  height: 48px;
  padding: 12px;
  border: none;
  border-radius: 8px;
  color: ${Color.Secondary};
  background-color: ${Color.Primary};
  &:hover {
    background-color: ${Color.Primary_Hover};
  }
  &:active,
  &:focus {
    background-color: ${Color.Primary_Pressed};
  }
`
export const PreviewImageProfile = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 8px;
  border: 1px solid ${Color.Disabled};
  object-fit: cover;
  object-position: center;
`
export const DeleteProfileImage = styled.div`
  background-color: ${Color.Secondary};
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 9px;
  right: 0;
  cursor: pointer;
  margin: 5px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${Color.Secondary_Hover};
  }
`
