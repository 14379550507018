import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "../../components"
import { Color, FontFormat, WindowSize } from "../../util"
import { FlexContainer, H2, P } from "../../style/main"
import { ImageBackground, TextErrorContainer } from "./styled"
import { background_error } from "../../img"

export const Error = () => {
  const navigate = useNavigate()
  const windowSize = WindowSize()
  useEffect(() => {
    document.documentElement.style.height = "100vh"
  }, [])
  return (
    <div>
      <FlexContainer
        $flexDirection={"column"}
        $justifyContent={"center"}
        $alignItems={"center"}
        $height={"50vh"}
        $padding={windowSize.width <= 768 && "32px"}
        style={{ position: "relative", zIndex: 3 }}
      >
        <H2
          style={{
            fontSize: windowSize.width <= 768 ? "64px" : "150px",
            fontWeight: "600",
          }}
        >
          404
        </H2>
        <TextErrorContainer>
          <P
            $FontFormat={
              windowSize.width <= 768
                ? FontFormat.Title_SM
                : FontFormat.Heading_MD
            }
            $textColor={Color.Tertiary}
            $lineHeight={"150%"}
          >
            PAGE NOT FOUND
          </P>
          <P
            $FontFormat={
              windowSize.width <= 768
                ? FontFormat.Title_SM
                : FontFormat.Heading_MD
            }
            $textColor={Color.Tertiary}
            $lineHeight={"150%"}
          >
            Sorry, we couldn’t find the page you are looking for.
          </P>
        </TextErrorContainer>
        <div style={{ marginTop: "32px" }}>
          <Button
            color={Color.Secondary}
            background={Color.Primary}
            label="Back to Homepage"
            onClick={() => navigate("/tickets")}
            style={{ whiteSpace: "nowrap" }}
          />
        </div>
      </FlexContainer>
      <FlexContainer
        $justifyContent={"center"}
        $alignItems={"center"}
        $width={"100%"}
      >
      </FlexContainer>
      <ImageBackground src={background_error} alt="background_error" />
    </div>
  )
}
