import React, { useState, useEffect, useContext } from "react"
import { firebaseConfig } from "../../services/auth/firebaseConfig"
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth"
import { useNavigate } from "react-router-dom"
import { ShowNavBarContext } from "../../components/Context"
import { EnvelopeIcon, LockClosedIcon } from "../../util/icons/Outline"
import { Color, FontFormat, Validator, WindowSize } from "../../util"
import { Input, Button } from "../../components"
import { logo_bluezebra_blue } from "../../img"
import { FlexContainer, H2, P } from "../../style/main"
import {
  ImageBackGroundContainer,
  TextContainer,
  LoginContainer,
  Logo,
  ResetPassText,
} from "./styled"
import CircularProgress from "@mui/material/CircularProgress"

export const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isValid, setisValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const [ischeckLogin, setIsCheckLogin] = useState(false)
  const { setIsShowNavBar } = useContext(ShowNavBarContext)
  const windowSize = WindowSize()
  const auth = getAuth(firebaseConfig)
  const navigate = useNavigate()
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const loginWithEmailAndPassword = async () => {
    let validate = true
    if (email === "") {
      validate = false
    } else if (password === "") {
      validate = false
    } else if (!emailRegex.test(email)) {
      validate = false
    }

    setisValid(validate)
    try {
      if (validate) {
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password)
          .then((res) => {
            const accessToken = res.user.accessToken
            localStorage.setItem("accessToken", accessToken)
            setLoading(false)
          })
          .catch((e) => {
            setLoading(false)
            setIsCheckLogin(true)
            console.log(e)
          })
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    const checkAuth = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          navigate("/profile")
          setIsShowNavBar(true)
        }
      })
    }

    const unsubscribe = checkAuth()
    setIsShowNavBar(false)

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [auth, navigate])

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      loginWithEmailAndPassword()
    }
  }
  return (
    <ImageBackGroundContainer>
      <FlexContainer
        $flexDirection={windowSize.width <= 768 && "column"}
        $justifyContent={
          windowSize.width <= 768 || windowSize.width >= 1921
            ? "center"
            : "space-around"
        }
        $width={"100%"}
        $height={"100%"}
        $alignItems={windowSize.width <= 768 && "center"}
        $gap={windowSize.width >= 1921 && "100px"}
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <TextContainer>
          <H2
            $FontFormat={
           windowSize.width <= 480
                ? FontFormat.Title_XL
                : FontFormat.Heading_XL
            }
            $textColor={Color.Tertiary}
            style={{
              lineHeight: "1.2",
              textShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
              position: "relative",
              zIndex: 1,
            }}
          >
            Welcome to <br />
            Bluezebra support system
          </H2>
          <P
            $FontFormat={
              windowSize.width <= 480
                ? FontFormat.Title_MD
                : FontFormat.Title_XL
            }
            $textColor={Color.Tertiary_Hover}
            style={{ lineHeight: "2.5", position: "relative", zIndex: 1 }}
          >
            Login to your portal
          </P>
        </TextContainer>
        <LoginContainer>
          <Logo src={logo_bluezebra_blue} alt="bluezebra_logo" />
          <FlexContainer
            $flexDirection={"column"}
            $gap={"20px"}
            $margin={"32px 0"}
            $width={windowSize.width <= 768 && "100%"}
          >
            <Input
              type="text"
              icon={
                <EnvelopeIcon
                  width={24}
                  height={24}
                  color={
                    (!isValid && !email) ||
                    (!isValid && !emailRegex.test(email)) ||
                    ischeckLogin
                      ? Color.Error
                      : "#000"
                  }
                />
              }
              label={"Email"}
              width={windowSize.width <= 1024 ? "100%" : "386px"}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isValid={
                (!isValid && !email) ||
                (!isValid && !emailRegex.test(email)) ||
                ischeckLogin
              }
            />
            {(!isValid && !email) ||
            (!isValid && !emailRegex.test(email)) ||
            ischeckLogin ? (
              <P style={Validator.errorStyle}>
                Please enter a valid email address.
              </P>
            ) : null}
            <Input
              type="password"
              icon={
                <LockClosedIcon
                  width={24}
                  height={24}
                  color={
                    (!isValid && !password) || ischeckLogin
                      ? Color.Error
                      : "#000"
                  }
                />
              }
              label={"Password"}
              width={windowSize.width <= 1024 ? "100%" : "386px"}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isValid={(!isValid && !password) || ischeckLogin}
              onKeyDown={handleKeyDown}
            />
            {(!isValid && !password) || ischeckLogin ? (
              <P style={Validator.errorStyle}>
                Please enter a valid password address.
              </P>
            ) : null}
            <ResetPassText to="/forgetPassword">Reset password</ResetPassText>
          </FlexContainer>
          <Button
            color={Color.Secondary}
            background={Color.Primary}
            label={
              loading ? (
                <CircularProgress
                  size={24}
                  style={{
                    color: Color.Secondary,
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                />
              ) : (
                "Login"
              )
            }
            onClick={loginWithEmailAndPassword}
          />
        </LoginContainer>
      </FlexContainer>
    </ImageBackGroundContainer>
  )
}
