// Font Size Heading
const Heading_XXL = {
  fontSize: "64px",
  fontWeight: 600,
}
const Heading_XL = {
  fontSize: "40px",
  fontWeight: 600,
}
const Heading_MD = {
  fontSize: "32px",
  fontWeight: 600,
}

// Font Size Title
const Title_XL = {
  fontSize: "24px",
  fontWeight: 600,
}
const Title_MD = {
  fontSize: "18px",
  fontWeight: 600,
}
const Title_SM = {
  fontSize: "16px",
  fontWeight: 600,
}
const Title_XS = {
  fontSize: "14px",
  fontWeight: 600,
}

// Font Size Body
const Body_MD = {
  fontSize: "18px",
  fontWeight: 400,
}
const Body_MD_Underline = {
  fontSize: "18px",
  fontWeight: 400,
}
const Body_SM = {
  fontSize: "16px",
  fontWeight: 400,
}
const Body_SM_Underline = {
  fontSize: "16px",
  fontWeight: 400,
}

// Font Size Label
const Label_MD = {
  fontSize: "14px",
  fontWeight: 400,
}

export const FontFormat = {
  Heading_XXL,
  Heading_XL,
  Heading_MD,
  Title_XL,
  Title_MD,
  Title_SM,
  Title_XS,
  Body_MD,
  Body_MD_Underline,
  Body_SM,
  Body_SM_Underline,
  Label_MD,
}
