// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-multi-carousel-list {
    position: unset;
}
.react-multi-carousel-item {
    display: flex;
    justify-content: center;
}
.ticket-create .react-multi-carousel-track {
    flex-direction: row-reverse;
}
.ticket-create .react-multiple-carousel__arrow--left {
    left: calc(20% + 1px);
    background: unset !important;
}
.ticket-create .react-multiple-carousel__arrow--right {
    right: calc(20% + 1px);
    background: unset !important;
}
.react-multiple-carousel__arrow,
.react-multiple-carousel__arrow:hover {
    background: unset;
}
.react-multiple-carousel__arrow::before {
    color: #000;
}
@media only screen and (max-width: 480px) {
    .ticket-create .react-multiple-carousel__arrow--left,
    .ticket-create .react-multiple-carousel__arrow--right {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/tickets/styled.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,qBAAqB;IACrB,4BAA4B;AAChC;AACA;IACI,sBAAsB;IACtB,4BAA4B;AAChC;AACA;;IAEI,iBAAiB;AACrB;AACA;IACI,WAAW;AACf;AACA;IACI;;QAEI,aAAa;IACjB;AACJ","sourcesContent":[".react-multi-carousel-list {\n    position: unset;\n}\n.react-multi-carousel-item {\n    display: flex;\n    justify-content: center;\n}\n.ticket-create .react-multi-carousel-track {\n    flex-direction: row-reverse;\n}\n.ticket-create .react-multiple-carousel__arrow--left {\n    left: calc(20% + 1px);\n    background: unset !important;\n}\n.ticket-create .react-multiple-carousel__arrow--right {\n    right: calc(20% + 1px);\n    background: unset !important;\n}\n.react-multiple-carousel__arrow,\n.react-multiple-carousel__arrow:hover {\n    background: unset;\n}\n.react-multiple-carousel__arrow::before {\n    color: #000;\n}\n@media only screen and (max-width: 480px) {\n    .ticket-create .react-multiple-carousel__arrow--left,\n    .ticket-create .react-multiple-carousel__arrow--right {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
