import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { firebaseConfig } from "../../services/auth/firebaseConfig"
import {
    getAuth,
    verifyPasswordResetCode,
    confirmPasswordReset,
} from "firebase/auth"
import { ShowNavBarContext } from "../../components/Context"
import { LockClosedIcon } from "../../util/icons/Outline"
import { Color, FontFormat, Validator, WindowSize } from "../../util"
import { Input, Button } from "../../components"
import { logo_bluezebra_blue } from "../../img"
import { FlexContainer, H2, P } from "../../style/main"
import { Error } from "../index"
import {
    ImageBackGroundContainer,
    TextContainer,
    LoginContainer,
    Logo,
} from "./styled"
import CircularProgress from "@mui/material/CircularProgress"

export const ResetPassword = () => {
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isValid, setisValid] = useState(true)
    const [isSend, setIsSend] = useState(false)
    const [loading, setLoading] = useState(false)
    const auth = getAuth(firebaseConfig)
    const urlParams = new URLSearchParams(window.location.search)
    const navigate = useNavigate()
    const windowSize = WindowSize()
    const mode = urlParams.get("mode")
    const actionCode = urlParams.get("oobCode")
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/
    const { setIsShowNavBar } = useContext(ShowNavBarContext)

    useEffect(() => {
        setIsShowNavBar(false)
    }, [setIsShowNavBar])

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            onConfirmPassword()
        }
    }

    const onConfirmPassword = async () => {
        let validate = true

        if (password === "" && confirmPassword === "") {
            validate = false
        } else if (!regex.test(password)) {
            validate = false
        } else if (password !== confirmPassword) {
            validate = false
        }
        setisValid(validate)
        try {
            if (validate) {
                setLoading(true)
                if (mode === "resetPassword") {
                    verifyPasswordResetCode(auth, actionCode).then((email) => {
                        const accountEmail = email
                        if (accountEmail) {
                            confirmPasswordReset(auth, actionCode, password)
                                .then(() => {
                                    setIsSend(true)
                                    setLoading(false)
                                })
                                .catch(() => setLoading(false))
                        }
                    })
                }
            }
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <>
            {mode === "resetPassword" ? (
                <ImageBackGroundContainer>
                    <FlexContainer
                        $flexDirection={windowSize.width <= 768 && "column"}
                        $justifyContent={
                            windowSize.width <= 768 || windowSize.width >= 1921
                                ? "center"
                                : "space-around"
                        }
                        $width={"100%"}
                        $height={"100%"}
                        $alignItems={windowSize.width <= 768 && "center"}
                        $gap={windowSize.width >= 1921 && "100px"}
                        style={{ paddingTop: "30px", paddingBottom: "30px" }}
                    >
                        <TextContainer>
                            <H2
                                $FontFormat={
                                    windowSize.width <= 480
                                        ? FontFormat.Title_XL
                                        : FontFormat.Heading_XL
                                }
                                $textColor={Color.Tertiary}
                                style={{
                                    lineHeight: "1.2",
                                    textShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                                    position: "relative",
                                    zIndex: 1,
                                }}
                            >
                                Welcome to <br />
                                Bluezebra support system
                            </H2>
                            <P
                                $FontFormat={
                                    windowSize.width <= 480
                                        ? FontFormat.Title_MD
                                        : FontFormat.Title_XL
                                }
                                $textColor={Color.Tertiary_Hover}
                                style={{ lineHeight: "2.5", position: "relative", zIndex: 1 }}
                            >
                                Login to your portal
                            </P>
                        </TextContainer>
                        <LoginContainer>
                            <Logo src={logo_bluezebra_blue} alt="bluezebra_logo" />
                            {isSend ? (
                                <FlexContainer
                                    $flexDirection={"column"}
                                    $alignItems={"center"}
                                    $gap={"16px"}
                                >
                                    <LockClosedIcon width={85} height={85} color={Color.Primary} />
                                    <P $FontFormat={FontFormat.Title_MD} $textColor={Color.Tertiary}>
                                        You can now use your new password to login to your account.
                                    </P>
                                    <Button
                                        color={Color.Secondary}
                                        background={Color.Primary}
                                        label={"Back to login"}
                                        onClick={() => navigate("/login")}
                                    />
                                </FlexContainer>
                            ) : (
                                <>
                                    <FlexContainer
                                        $flexDirection={"column"}
                                        $gap={"8px"}
                                        $width={windowSize.width <= 768 && "100%"}
                                    >
                                        <Input
                                            type="password"
                                            icon={
                                                <LockClosedIcon
                                                    width={24}
                                                    height={24}
                                                    color={!isValid && !password ? Color.Error : "#000"}
                                                />
                                            }
                                            label={"Password"}
                                            width={windowSize.width <= 1024 ? "100%" : "386px"}
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            isValid={!isValid && !password}
                                            onKeyDown={handleKeyDown}
                                        />
                                        {!isValid && !password ? (
                                            <P style={{ ...Validator.errorStyle, top: 0 }}>
                                                Please enter a valid password address.
                                            </P>
                                        ) : !isValid && password.length < 8 ? (
                                            <P style={{ ...Validator.errorStyle, top: 0 }}>
                                                Password must be at least 8 characters long.
                                            </P>
                                        ) : !isValid && !regex.test(password) ? (
                                            <P style={{ ...Validator.errorStyle, top: 0, width: 386 }}>
                                                Must be at least 8 characters including A-Z, a-z, 0-9 and a special character
                                            </P>
                                        ) : (
                                            <P
                                                $FontFormat={FontFormat.Label_MD}
                                                $textColor={Color.Tertiary}
                                                style={{ width: 386 }}
                                            >
                                                Must be at least 8 characters including A-Z, a-z, 0-9 and a special character
                                            </P>
                                        )}
                                    </FlexContainer>
                                    <FlexContainer
                                        $flexDirection={"column"}
                                        $gap={"20px"}
                                        $margin={"32px 0"}
                                        $width={windowSize.width <= 768 && "100%"}
                                    >
                                        <Input
                                            type="password"
                                            icon={
                                                <LockClosedIcon
                                                    width={24}
                                                    height={24}
                                                    color={!isValid && !confirmPassword ? Color.Error : "#000"}
                                                />
                                            }
                                            label={"Confirm Password"}
                                            width={windowSize.width <= 1024 ? "100%" : "386px"}
                                            name="password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            isValid={!isValid && !confirmPassword}
                                            onKeyDown={handleKeyDown}
                                        />
                                        {!isValid && !confirmPassword ? (
                                            <P style={{ ...Validator.errorStyle, top: 0 }}>
                                                Please enter a valid Confirm password address.
                                            </P>
                                        ) : !isValid && password !== confirmPassword ? (
                                            <P style={{ ...Validator.errorStyle, top: 0 }}>
                                                Password and Confirm Password do not match.
                                            </P>
                                        ) : !isValid && confirmPassword.length < 8 ? (
                                            <P style={{ ...Validator.errorStyle, top: 0 }}>
                                                Password must be at least 8 characters long.
                                            </P>
                                        ) : null}
                                    </FlexContainer>
                                    <Button
                                        color={Color.Secondary}
                                        background={Color.Primary}
                                        label={
                                            loading ? (
                                                <CircularProgress
                                                    size={24}
                                                    style={{
                                                        color: Color.Secondary,
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                    }}
                                                />
                                            ) : (
                                                "Reset password"
                                            )
                                        }
                                        onClick={onConfirmPassword}
                                    />
                                </>
                            )}
                        </LoginContainer>
                    </FlexContainer>
                </ImageBackGroundContainer>
            ) : (
                <Error />
            )}
        </>
    )
}