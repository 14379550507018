const METHOD = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
}
const CONTENT_TYPE = {
  JSON: "application/json",
  FORM_DATA: "multipart/form-data",
}
const SELECT_TICKET_STATUS = {
  ALL: "All",
  PENDING: "Pending",
  IN_PROGRESS: "In Progress",
  CLOSED: "Closed",
}

export const Enum = {
  METHOD,
  CONTENT_TYPE,
  SELECT_TICKET_STATUS,
}
