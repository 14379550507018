import React from "react"
import { Color } from "../../util"
import { FlexContainer } from "../../style/main"
import PaginationMui from "@mui/material/Pagination"

export const Pagination = ({ count, page, limit, onChange }) => {
  const PaginationSX = {
    "& .MuiPaginationItem-root": {
        border: "unset",
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
        backgroundColor: Color.Primary,
        color: Color.Secondary,
    }
  }
  const totalPages = Math.ceil(count / limit);

  return (
    <>
      {count > limit && (
        <FlexContainer
          $justifyContent={"center"}
          $margin={"16px 0"}
          $width={"100%"}
        >
          <PaginationMui
            count={totalPages}
            page={page}
            onChange={onChange}
            variant="outlined"
            shape="rounded"
            sx={PaginationSX}
          />
        </FlexContainer>
      )}
    </>
  )
}
