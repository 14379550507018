import React, { useRef, useState, useEffect } from "react"
import { Color, FontFormat, Enum, WindowSize } from "../../util"
import { Button } from "../../components"
import {
  PlusIcon,
  XMarkIcon,
  ArrowLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloudArrowDownIcon,
  ClockIcon,
  DocumentTextIcon,
} from "../../util/icons/Outline"
import { SolidXCircleIcon } from "../../util/icons/Solid"
import { FlexContainer, H2, P, Span } from "../../style/main"
import {
  Container,
  DetailContainer,
  OverlayContainer,
  MenuContainer,
  HeadContainer,
  BodyContainer,
  DateContainer,
  StatusBox,
  ClientImage,
  ReferCaseText,
  Description,
  SeeMoreText,
  SupervisorsBox,
  MessageContainer,
  MessageCard,
  MessageImageUser,
  MessageNameUser,
  MessageText,
  LineContainer,
  Line,
  DropFile,
  TextEditorContainer,
  PreviewImageFile,
  PreviewFilesBox,
  AddButton,
  BackButton,
  PreviewImageInMassage,
  FilesContainer,
  MessageFilesContainer,
  FilesBox,
  PopupShowImageContainer,
  SliderBluezebraContainer,
  SliderContainer,
  PreviewImage,
} from "./styled"
import {
  getTicketById,
  getTicketMessageById,
  createTicketMessage,
} from "../../api"
import { userInfo } from "../../auth"
import { useParams } from "react-router-dom"
import Avatar from "@mui/material/Avatar"
import Skeleton from "@mui/material/Skeleton"
import CircularProgress from "@mui/material/CircularProgress"
import dayjs from "dayjs"
import ReactQuill from "react-quill"
import Carousel from "react-multi-carousel"
import "react-quill/dist/quill.snow.css"
import "react-multi-carousel/lib/styles.css"
import "./styled.css"

export const TicketsDetail = () => {
  const { ticketId } = useParams()
  const [ticket, setTicket] = useState([])
  const [supporters, setSupporters] = useState([])
  const [fetchedUser, setFetchedUser] = useState([])
  const [ticketMessage, setTicketMessage] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [date, setDate] = useState({
    create: null,
    startDate: null,
    endDate: null,
  })
  const [formatDate, setFormatDate] = useState({
    textEditor: "",
    uploadFiles: [],
  })
  const [previewFiles, setPreviewFiles] = useState([
    {
      name: "",
      type: "",
      url: "",
    },
  ])
  const [isSave, setIsSave] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [loadingTicket, setLoadingTicket] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [isSendLoading, setIsSendLoading] = useState(false)
  const [isShowDetail, setIsShowDetail] = useState(true)
  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)
  const [isShowImage, setIsShowImage] = useState(false)
  const windowSize = WindowSize()
  const fileInputRef = useRef()
  const messagerRef = useRef(null)

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        if (isSave) {
          setIsSave(false)
        }
        setLoadingTicket(true)
        const ticket = await getTicketById(ticketId)
        const supporters = ticket[0].user_ticket
        setTicket(ticket[0])
        setSupporters(supporters)
        setLoadingTicket(false)

        const user = await userInfo()
        setFetchedUser(user)

        const ticketMessage = await getTicketMessageById(ticketId)
        setTicketMessage(ticketMessage)
      } catch (e) {
        console.error(e)
        setLoadingTicket(false)
      }
    }
    fetchTicket()
  }, [ticketId, isSave])
  useEffect(() => {
    if (ticket && ticket.created_at && ticket.updated_at) {
      const Created = dayjs(ticket.created_at).format("D MMM YYYY, h.mm A")
      let StartDate = null
      const EndDate = dayjs(ticket.updated_at).format("D MMM YYYY, h.mm A")

      if (Array.isArray(ticket.user_ticket)) {
        const mainUserRole = ticket.user_ticket.find(
          (user) => user.ticket_role && user.ticket_role.name === "Main"
        )
        if (mainUserRole && mainUserRole.created_at) {
          const createdAt = mainUserRole.created_at
          StartDate = dayjs(createdAt).format("D MMM YYYY, h.mm A")
        }
      }

      setDate({
        create: Created,
        startDate: StartDate,
        endDate: EndDate,
      })
    }
  }, [ticket])
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1401 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1400, min: 997 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 996, min: 769 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  }
  const responsiveBluezebra = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1501 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1301 },
      items: 2,
    },
    laptop: {
      breakpoint: { max: 1300, min: 1025 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  }
  const textColor = (item) => {
    switch (item) {
      case "Pending":
        return "#F4C900"
      case "In Progress":
        return "#007D64"
      case "Supporter":
      case "Main":
        return Color.Primary
      case "Closed":
        return Color.Error
      default:
        return Color.Primary
    }
  }
  const background = (item) => {
    switch (item) {
      case "Pending":
        return "#FEFBE4"
      case "In Progress":
        return "#E0F3F0"
      case "Supporter":
        return Color.Secondary
      case "Main":
        return Color.Baby_Blue
      case "Closed":
        return "#F9E5EB"
      default:
        return Color.Secondary
    }
  }
  const toolbarOptions = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        { align: [] },
        { list: "ordered" },
        { list: "bullet" },
        { list: "check" },
        { color: [] },
        { background: [] },
      ],
    ],
    clipboard: {
      matchVisual: false,
    },
  }
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }
  const onTextEditorChange = (value) => {
    setFormatDate((prev) => {
      return {
        ...prev,
        textEditor: value,
      }
    })
  }
  const onClearForm = () => {
    setIsSendLoading(false)
    setFormatDate({
      textEditor: "",
      uploadFiles: [],
    })
    setPreviewFiles([
      {
        name: "",
        type: "",
        url: "",
      },
    ])
  }
  const onSendMessage = () => {
    let validate = true
    const plainText = formatDate.textEditor.replace(/<[^>]+>/g, "").trim()

    if (plainText === "" && formatDate.uploadFiles.length === 0) {
      validate = false
    }
    const data = {
      ticketId: ticketId,
      userId: fetchedUser.id,
      message: formatDate.textEditor,
      uploadFile: formatDate.uploadFiles,
      uploadFileName: formatDate.uploadFiles.map((file) => file.name),
      subject: ticket.title,
      status: ticket.status,
      caseId: ticket.ticket_number,
      email: ticket.user_ticket,
    }

    if (validate) {
      setIsSendLoading(true)
      createTicketMessage(data)
        .catch((e) => {
          console.log(e)
          setIsSendLoading(false)
        })
        .then(() => {
          setIsSendLoading(false)
          setIsSave(true)
          onClearForm()
        })
    }
  }
  const onClickUploadFile = () => {
    fileInputRef.current.click()
  }
  const handleUploadFile = (e) => {
    const files = e.target.files
    let selectedFiles = []
    let imageFiles = []
    let readers = []

    try {
      setIsUpload(true)
      for (let i = 0; i < files.length && i < 10; i++) {
        const file = files[i]
        formatDate.uploadFiles.push(file)
        selectedFiles.push(file)
        const reader = new FileReader()
        imageFiles.push(reader)
        readers.push(
          new Promise((resolve, reject) => {
            reader.onload = () => {
              resolve(reader.result)
            }
            reader.onerror = () => {
              reader.abort()
              reject(new DOMException("Problem parsing input file."))
            }
          })
        )
        reader.readAsDataURL(file)
      }

      Promise.all(readers).then((results) => {
        setPreviewFiles((prevFiles) => {
          const filteredPrevFiles = prevFiles.filter(
            (file) => file.name !== "" || file.url !== ""
          )
          const newFiles = selectedFiles.map((file, index) => ({
            name: file.name,
            type: file.type,
            url: results[index] ? results[index] : "",
          }))
          return [...filteredPrevFiles, ...newFiles]
        })
        setIsUpload(false)
      })
    } catch (error) {
      console.error("Error handling file upload:", error)
    }
  }
  const handleDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    handleUploadFile({ target: { files } })
  }
  const handleDragOver = (e) => {
    e.preventDefault()
  }
  const handleDeleteFile = (index) => {
    setFormatDate((prevState) => ({
      ...prevState,
      uploadFiles: prevState.uploadFiles?.filter((file, i) => i !== index),
    }))
    setPreviewFiles((prevFiles) => prevFiles.filter((file, i) => i !== index))
  }
  const renderPopupshowImage = (images, index) => {
    const isFirstImage = index === 0
    const isLastImage = index === images.length - 1
    return (
      <>
        <OverlayContainer />
        <PopupShowImageContainer>
          <FlexContainer
            $justifyContent={"flex-end"}
            $gap={"10px"}
            $padding={"16px"}
          >
            <a href={images[index].file} download={images[index].file_name}>
              <CloudArrowDownIcon
                width={"40px"}
                height={"40px"}
                color={Color.Secondary}
                style={{ cursor: "pointer" }}
              />
            </a>
            <SolidXCircleIcon
              width={"40px"}
              height={"40px"}
              onClick={() => setIsShowImage(false)}
              color={Color.Secondary}
              style={{ cursor: "pointer" }}
            />
          </FlexContainer>
          <FlexContainer
            $flexDirection={"column"}
            $justifyContent={"center"}
            $alignItems={"center"}
            $height={"100vh"}
          >
            <FlexContainer $justifyContent={"center"} $alignItems={"center"}>
              {!isFirstImage && (
                <ChevronLeftIcon
                  width={windowSize.width <= 600 ? "16px" : "36px"}
                  height={windowSize.width <= 600 ? "16px" : "36px"}
                  strokeWidth={"2"}
                  onClick={onPrevious}
                  color={Color.Secondary}
                  style={{ cursor: "pointer" }}
                />
              )}
              <PreviewImage
                key={index}
                src={images[index].file}
                alt={images[index].file_name}
                href={images[index].file}
                download={images[index].file_name}
              />
              {!isLastImage && (
                <ChevronRightIcon
                  width={windowSize.width <= 600 ? "16px" : "36px"}
                  height={windowSize.width <= 600 ? "16px" : "36px"}
                  strokeWidth={"2"}
                  onClick={onNext}
                  color={Color.Secondary}
                  style={{ cursor: "pointer" }}
                />
              )}
            </FlexContainer>
            <P
              $FontFormat={FontFormat.Title_MD}
              $textColor={Color.Secondary}
              $lineHeight={"3"}
              style={{ letterSpacing: "3px" }}
            >
              {index + 1}/{images.length}
            </P>
          </FlexContainer>
        </PopupShowImageContainer>
      </>
    )
  }
  const renderHeadContainerForDesktop = () => {
    return (
      <>
        <DetailContainer>
          <FlexContainer
            $alignItems={"center"}
            $gap={"16px"}
            $width={"100%"}
            style={{ paddingBottom: "8px" }}
          >
            <H2
              $FontFormat={FontFormat.Title_XL}
              $whiteSpace={"nowrap"}
              $overFlow={"hidden"}
              $textOverflow={"ellipsis"}
              $width={"auto"}
            >
              Subject: {ticket && ticket.title}
            </H2>
            <StatusBox
              $textColor={textColor(ticket && ticket.status)}
              $background={background(ticket && ticket.status)}
              $width={
                ticket &&
                ticket.status === Enum.SELECT_TICKET_STATUS.IN_PROGRESS &&
                "108px"
              }
            >
              {ticket && ticket.status}
            </StatusBox>
          </FlexContainer>
          <FlexContainer
            $gap={"5px"}
            $width={"100%"}
            style={{ paddingBottom: "8px" }}
          >
            <div style={{ width: "100%" }}>
              <Description $maxHeight={expanded ? "none" : "3em"}>
                <Span $FontFormat={FontFormat.Title_MD}>Description:</Span>{" "}
                {ticket && ticket.detail}
              </Description>
              {ticket?.detail?.length > 300 && (
                <SeeMoreText onClick={toggleExpanded}>
                  {expanded ? "See Less" : "See More"}
                </SeeMoreText>
              )}
            </div>
          </FlexContainer>
          <FlexContainer
            $alignItems={"center"}
            $gap={"16px"}
            style={{ paddingBottom: "8px" }}
          >
            <P $FontFormat={FontFormat.Title_MD}>
              Product:{" "}
              <Span $FontFormat={FontFormat.Body_MD}>
                {ticket && ticket.product?.name}
              </Span>
            </P>
            <P $FontFormat={FontFormat.Body_MD}> | </P>
            <FlexContainer $alignItems={"center"} $gap={"8px"}>
              <P $FontFormat={FontFormat.Title_MD}>Client: </P>
              {!ticket.client_user?.logo_image ? (
                <Avatar
                  src={ticket && ticket.client_user?.logo_image}
                  alt={ticket && ticket.client_user?.name}
                  style={{ width: "36px", height: "36px" }}
                />
              ) : (
                <ClientImage
                  src={ticket && ticket.client_user?.logo_image}
                  alt={ticket && ticket.client_user?.name}
                />
              )}
              <Span $FontFormat={FontFormat.Body_MD}>
                {ticket && ticket.client_user?.name}
              </Span>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer $alignItems={"center"} $gap={"8px"}>
            <P
              $FontFormat={FontFormat.Title_MD}
              style={{ paddingBottom: "8px" }}
            >
              Refer case:{" "}
              {ticket &&
              ticket.ticket_reference &&
              ticket.ticket_reference.length === 0 ? (
                <Span $FontFormat={FontFormat.Body_MD}>-</Span>
              ) : (
                <ReferCaseText
                  to={`/tickets/detail/${
                    ticket &&
                    ticket.ticket_reference &&
                    ticket.ticket_reference.id
                  }`}
                >
                  {ticket && ticket.ticket_reference?.name}
                </ReferCaseText>
              )}
            </P>
          </FlexContainer>
          <FlexContainer
            $alignItems={"center"}
            $gap={"16px"}
            style={{ paddingBottom: "8px" }}
          >
            <P $FontFormat={FontFormat.Title_MD}>Bluezebra:</P>
            {ticket && ticket.user_ticket && ticket.user_ticket.length !== 0 ? (
              <SliderBluezebraContainer>
                <Carousel
                  className="supporter ticket-message-file"
                  responsive={responsiveBluezebra}
                >
                  {supporters &&
                    supporters.length > 0 &&
                    supporters.map((item, index) => (
                      <SupervisorsBox key={index}>
                        <ClientImage
                          key={index}
                          src={item.bluezebra_user.profile_image}
                          alt={item.bluezebra_user.name}
                        />
                        <Span
                          $FontFormat={FontFormat.Body_MD}
                          $whiteSpace={"nowrap"}
                          $overFlow={"hidden"}
                          $textOverflow={"ellipsis"}
                          $maxWidth={"100px"}
                        >
                          {item.bluezebra_user.name}
                        </Span>
                        <StatusBox
                          $textColor={textColor(item.ticket_role.name)}
                          $background={background(item.ticket_role.name)}
                        >
                          {item.ticket_role.name}
                        </StatusBox>
                      </SupervisorsBox>
                    ))}
                </Carousel>
              </SliderBluezebraContainer>
            ) : (
              <Span $FontFormat={FontFormat.Body_MD}>-</Span>
            )}
          </FlexContainer>
          <FlexContainer
            $alignItems={"center"}
            $gap={"16px"}
            style={{ paddingBottom: "8px" }}
          >
            <P $FontFormat={FontFormat.Title_MD} $whiteSpace={"nowrap"}>
              Attached files:
            </P>
            {ticket && ticket.ticket_file && ticket.ticket_file.length > 0 ? (
              <SliderContainer>
                <Carousel
                  className="ticket-message-file"
                  responsive={responsive}
                >
                  {ticket &&
                    ticket.ticket_file.length > 0 &&
                    ticket.ticket_file.map((item, index) => (
                      <FilesContainer key={index}>
                        <FilesBox href={item.file} download={item.file_name}>
                          {item.file_name}
                        </FilesBox>
                      </FilesContainer>
                    ))}
                </Carousel>
              </SliderContainer>
            ) : (
              <Span $FontFormat={FontFormat.Body_MD}>-</Span>
            )}
          </FlexContainer>
        </DetailContainer>
        <DateContainer $backgroundColor={background(ticket && ticket.status)}>
          <P $FontFormat={FontFormat.Body_MD} $whiteSpace={"nowrap"}>
            Created: {date.create}
          </P>
          <P $FontFormat={FontFormat.Body_MD} $whiteSpace={"nowrap"}>
            Start date:{" "}
            {(ticket &&
              ticket.status === Enum.SELECT_TICKET_STATUS.IN_PROGRESS) ||
            (ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED)
              ? date.startDate
              : "-"}
          </P>
          <P $FontFormat={FontFormat.Body_MD} $whiteSpace={"nowrap"}>
            End date:{" "}
            {ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED
              ? date.endDate
              : "-"}
          </P>
        </DateContainer>
      </>
    )
  }
  const renderHeadContainerForMobile = () => {
    return (
      <>
        <div style={{ width: "100%" }}>
          <FlexContainer $gap={"4px"}>
            <StatusBox
              $textColor={textColor(ticket && ticket.status)}
              $background={background(ticket && ticket.status)}
            >
              {ticket && ticket.status}
            </StatusBox>
          </FlexContainer>
          <FlexContainer
            $flexDirection={"column"}
            $gap={"16px"}
            $width={"100%"}
            style={{ paddingBottom: "4px" }}
          >
            <H2
              $FontFormat={FontFormat.Title_XL}
              $whiteSpace={"nowrap"}
              $overFlow={"hidden"}
              $textOverflow={"ellipsis"}
              $width={"auto"}
            >
              Subject: {ticket && ticket.title}
            </H2>
            <div style={{ width: "100%" }}>
              <Description $maxHeight={expanded ? "none" : "3em"}>
                <Span $FontFormat={FontFormat.Title_MD}>Description:</Span>{" "}
                {ticket && ticket.detail}
              </Description>
              {ticket?.detail?.length > 300 && (
                <SeeMoreText onClick={toggleExpanded}>
                  {expanded ? "See Less" : "See More"}
                </SeeMoreText>
              )}
            </div>
          </FlexContainer>
          {!isShowDetail && (
            <>
              <P
                $FontFormat={FontFormat.Title_MD}
                style={{ paddingBottom: "4px" }}
              >
                Product:{" "}
                <Span $FontFormat={FontFormat.Body_MD}>
                  {ticket && ticket.product?.name}
                </Span>
              </P>
              <FlexContainer
                $alignItems={"center"}
                $gap={"8px"}
                style={{ paddingBottom: "4px" }}
              >
                <P $FontFormat={FontFormat.Title_MD}>Client: </P>
                {!ticket.client_user?.logo_image ? (
                  <Avatar
                    src={ticket && ticket.client_user?.logo_image}
                    alt={ticket && ticket.client_user?.name}
                    style={{ width: "36px", height: "36px" }}
                  />
                ) : (
                  <ClientImage
                    src={ticket && ticket.client_user?.logo_image}
                    alt={ticket && ticket.client_user?.name}
                  />
                )}
                <Span $FontFormat={FontFormat.Body_MD}>
                  {ticket && ticket.client_user?.name}
                </Span>
              </FlexContainer>
              <P
                $FontFormat={FontFormat.Title_MD}
                style={{ paddingBottom: "4px" }}
              >
                Refer case: <Span $FontFormat={FontFormat.Body_MD}>-</Span>
              </P>
              <FlexContainer
                $alignItems={"center"}
                $gap={"16px"}
                $width={"100%"}
                style={{ paddingBottom: "4px" }}
              >
                <P $FontFormat={FontFormat.Title_MD}>Bluezebra:</P>
                {ticket &&
                ticket.user_ticket &&
                ticket.user_ticket.length !== 0 ? (
                  <SliderBluezebraContainer>
                    <Carousel
                      className="supporter ticket-message-file"
                      responsive={responsiveBluezebra}
                    >
                      {supporters &&
                        supporters.length > 0 &&
                        supporters.map((item, index) => (
                          <SupervisorsBox key={index}>
                            <ClientImage
                              key={index}
                              src={item.bluezebra_user.profile_image}
                              alt={item.bluezebra_user.name}
                            />
                            <Span
                              $FontFormat={FontFormat.Body_MD}
                              $whiteSpace={"nowrap"}
                              $overFlow={"hidden"}
                              $textOverflow={"ellipsis"}
                              $maxWidth={"100px"}
                            >
                              {item.bluezebra_user.name}
                            </Span>
                            <StatusBox
                              $textColor={textColor(item.ticket_role.name)}
                              $background={background(item.ticket_role.name)}
                            >
                              {item.ticket_role.name}
                            </StatusBox>
                          </SupervisorsBox>
                        ))}
                    </Carousel>
                  </SliderBluezebraContainer>
                ) : (
                  <Span $FontFormat={FontFormat.Body_MD}>-</Span>
                )}
              </FlexContainer>
              <FlexContainer
                $alignItems={"center"}
                $gap={"16px"}
                style={{ paddingBottom: "8px" }}
              >
                <P $FontFormat={FontFormat.Title_MD} $whiteSpace={"nowrap"}>
                  Attached files:
                </P>
                {ticket &&
                  ticket.ticket_file &&
                  ticket.ticket_file.length === 0 && (
                    <Span $FontFormat={FontFormat.Body_MD}>-</Span>
                  )}
                <SliderContainer>
                  <Carousel
                    className="ticket-message-file"
                    responsive={responsive}
                  >
                    {ticket &&
                      ticket.ticket_file &&
                      ticket.ticket_file.map((item, index) => (
                        <FilesContainer key={index}>
                          <FilesBox href={item.file} download={item.file_name}>
                            {item && item.file_name}
                          </FilesBox>
                        </FilesContainer>
                      ))}
                  </Carousel>
                </SliderContainer>
              </FlexContainer>
              <DateContainer
                $backgroundColor={background(ticket && ticket.status)}
              >
                <P $FontFormat={FontFormat.Body_SM}>Created: {date.create}</P>
                <P $FontFormat={FontFormat.Body_SM}>
                  Start date:
                  {(ticket &&
                    ticket.status === Enum.SELECT_TICKET_STATUS.IN_PROGRESS) ||
                  (ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED)
                    ? date.startDate
                    : "-"}
                </P>
                <P $FontFormat={FontFormat.Body_SM}>
                  End date:{" "}
                  {ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED
                    ? date.endDate
                    : "-"}
                </P>
              </DateContainer>
            </>
          )}
          <FlexContainer>
            {isShowDetail ? (
              <ChevronDownIcon
                width={24}
                height={24}
                style={{ cursor: "pointer", margin: "auto" }}
                onClick={() => setIsShowDetail(!isShowDetail)}
              />
            ) : (
              <ChevronUpIcon
                width={24}
                height={24}
                style={{ cursor: "pointer", margin: "auto" }}
                onClick={() => setIsShowDetail(!isShowDetail)}
              />
            )}
          </FlexContainer>
        </div>
      </>
    )
  }
  const SkeletonHeadContainerForDesktop = () => {
    return (
      <>
        <DetailContainer>
          <FlexContainer $alignItems={"center"} $gap={"16px"} $width={"100%"}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Title_XL.fontSize,
                width: "250px",
              }}
            />
            <Skeleton
              variant="rounded"
              width={"108px"}
              height={"37px"}
              sx={{ borderRadius: "8px" }}
            />
          </FlexContainer>
          <FlexContainer $flexDirection={"column"} $width={"100%"}>
            <FlexContainer $gap={"5px"} $width={"100%"}>
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  fontSize: FontFormat.Title_MD.fontSize,
                  width: "20%",
                }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  fontSize: FontFormat.Title_MD.fontSize,
                  width: "80%",
                }}
              />
            </FlexContainer>
            {Array.from({ length: 3 }).map((_, index) => {
              return (
                <Skeleton
                  key={index}
                  variant="text"
                  animation="wave"
                  sx={{
                    fontSize: FontFormat.Title_MD.fontSize,
                    width: "100%",
                  }}
                />
              )
            })}
          </FlexContainer>
          <FlexContainer $alignItems={"center"} $gap={"16px"}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Title_MD.fontSize,
                width: "150px",
              }}
            />
            <FlexContainer $alignItems={"center"} $gap={"8px"}>
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  fontSize: FontFormat.Title_MD.fontSize,
                  width: "80px",
                }}
              />
              <Skeleton
                variant="circular"
                width={"36px"}
                height={"36px"}
                animation="wave"
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  fontSize: FontFormat.Body_MD.fontSize,
                  width: "150px",
                }}
              />
            </FlexContainer>
          </FlexContainer>
          <Skeleton
            variant="text"
            animation="wave"
            sx={{
              fontSize: FontFormat.Title_MD.fontSize,
              width: "150px",
            }}
          />
          <FlexContainer $alignItems={"center"} $gap={"16px"}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Title_MD.fontSize,
                width: "120px",
              }}
            />
            <Skeleton
              variant="circular"
              width={"38px"}
              height={"38px"}
              animation="wave"
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Body_MD.fontSize,
                width: "150px",
              }}
            />
            <Skeleton variant="rounded" width={"60px"} height={"37px"} />
          </FlexContainer>
          <FlexContainer $gap={"16px"} $width={"100%"}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Body_MD.fontSize,
                width: "20%",
              }}
            />
            {Array.from({ length: 2 }).map((_, index) => {
              return (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={"40%"}
                  height={"37px"}
                />
              )
            })}
          </FlexContainer>
        </DetailContainer>
        <DateContainer $backgroundColor={`rgba(0, 0, 0, 0.11)`}>
          {Array.from({ length: 3 }).map((_, index) => {
            return (
              <Skeleton
                key={index}
                variant="text"
                animation="wave"
                sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "280px" }}
              />
            )
          })}
        </DateContainer>
      </>
    )
  }
  const SkeletonHeadContainerForMobile = () => {
    return (
      <>
        <FlexContainer
          $flexDirection={"column"}
          $alignItems={"flex-start"}
          $gap={"4px"}
          $width={"100%"}
        >
          <Skeleton variant="rounded" width={"108px"} height={"37px"} />
          <FlexContainer
            $flexDirection={"column"}
            $gap={"16px"}
            $width={"100%"}
          >
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: FontFormat.Title_XL.fontSize, width: "80%" }}
            />
            <FlexContainer $flexDirection={"column"} $width={"100%"}>
              <FlexContainer $gap={"5px"} $width={"100%"}>
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{
                    fontSize: FontFormat.Title_MD.fontSize,
                    width: "20%",
                  }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{
                    fontSize: FontFormat.Title_MD.fontSize,
                    width: "80%",
                  }}
                />
              </FlexContainer>
              {Array.from({ length: 2 }).map((_, index) => {
                return (
                  <Skeleton
                    key={index}
                    variant="text"
                    animation="wave"
                    sx={{
                      fontSize: FontFormat.Title_MD.fontSize,
                      width: "100%",
                    }}
                  />
                )
              })}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </>
    )
  }
  const onImageClick = (item, index) => {
    setSelectedImage(item)
    setSelectedImageIndex(index)
    setIsShowImage(true)
  }
  const onNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % selectedImage.length)
  }
  const onPrevious = () => {
    setSelectedImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + selectedImage.length) % selectedImage.length
    )
  }

  return (
    <Container>
      <FlexContainer $justifyContent={"center"}>
        <MenuContainer>
          <BackButton to={"/tickets"}>
            <ArrowLeftIcon
              width={24}
              height={24}
              strokeWidth={2}
              style={{ color: Color.Tertiary }}
            />
          </BackButton>
          {loadingTicket ? (
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Body_MD.fontSize,
                width: "150px",
                height: "33px",
                marginLeft: "auto",
              }}
            />
          ) : (
            <P $FontFormat={FontFormat.Title_MD} $textAlign={"right"}>
              Case ID {ticket && ticket.ticket_number}
            </P>
          )}
        </MenuContainer>
      </FlexContainer>
      <FlexContainer $justifyContent={"center"}>
        <HeadContainer>
          {loadingTicket ? (
            <>
              {windowSize.width <= 657
                ? SkeletonHeadContainerForMobile()
                : SkeletonHeadContainerForDesktop()}
            </>
          ) : (
            <>
              {windowSize.width <= 657
                ? renderHeadContainerForMobile()
                : renderHeadContainerForDesktop()}
            </>
          )}
        </HeadContainer>
      </FlexContainer>
      <FlexContainer $justifyContent={"center"}>
        <BodyContainer>
          {ticket && ticket.status === Enum.SELECT_TICKET_STATUS.PENDING && (
            <FlexContainer
              $flexDirection={"column"}
              $justifyContent={"center"}
              $alignItems={"center"}
              $gap={"8px"}
              style={{ position: "relative", top: "25%" }}
            >
              <ClockIcon width={"64px"} height={"64px"} color="#F4C900" />
              <P $FontFormat={FontFormat.Title_XL} $textAlign={"center"}>
                Currently awaiting authorization from the supervisor for the
                case.
              </P>
            </FlexContainer>
          )}
          <FlexContainer
            $flexDirection={"column"}
            $justifyContent={"flex-end"}
            style={{
              maxHeight: "1400px",
              filter:
                ticket &&
                ticket.status === Enum.SELECT_TICKET_STATUS.PENDING &&
                "blur(5px)",
              pointerEvents:
                ticket &&
                ticket.status === Enum.SELECT_TICKET_STATUS.PENDING &&
                "none",
            }}
          >
            {loadingTicket ? (
              <>
                <MessageContainer>
                  {Array.from({ length: 4 }).map((_, index) => {
                    return (
                      <MessageCard key={index}>
                        <FlexContainer $alignItems={"center"} $gap={"16px"}>
                          <Skeleton
                            variant="circular"
                            width={"48px"}
                            height={"48px"}
                            animation="wave"
                          />
                          <div>
                            <FlexContainer $alignItems={"center"} $gap={"12px"}>
                              <MessageNameUser>
                                <Skeleton
                                  variant="text"
                                  animation="wave"
                                  sx={{
                                    fontSize: FontFormat.Body_MD.fontSize,
                                    width: "180px",
                                  }}
                                />
                              </MessageNameUser>
                              <Skeleton
                                variant="rounded"
                                width={"60px"}
                                height={"37px"}
                                sx={{
                                  marginLeft: "auto",
                                  borderRadius: "8px",
                                }}
                              />
                            </FlexContainer>
                            <Skeleton
                              variant="text"
                              animation="wave"
                              sx={{
                                fontSize: FontFormat.Label_MD.fontSize,
                                width: "150px",
                              }}
                            />
                          </div>
                        </FlexContainer>
                        <div style={{ width: "100%" }}>
                          {Array.from({ length: 4 }).map((_, index) => (
                            <Skeleton
                              key={index}
                              variant="text"
                              animation="wave"
                              sx={{
                                fontSize: FontFormat.Body_MD.fontSize,
                                width: "80%",
                              }}
                            />
                          ))}
                        </div>
                      </MessageCard>
                    )
                  })}
                </MessageContainer>
                <TextEditorContainer>
                  <Skeleton
                    variant="rounded"
                    height={"40px"}
                    sx={{ borderRadius: "unset" }}
                  />
                  <div style={{ height: "237px" }}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Body_MD.fontSize,
                        width: "280px",
                        marginLeft: "16px",
                      }}
                    />
                  </div>
                  <div style={{ margin: "0 32px", marginBottom: "32px" }}>
                    <Skeleton
                      variant="rounded"
                      width={"70px"}
                      height={"41px"}
                      sx={{
                        marginLeft: "auto",
                        borderRadius: "8px",
                      }}
                    />
                    <LineContainer>
                      <Line />
                    </LineContainer>
                    {windowSize.width <= 657 ? (
                      <FlexContainer $justifyContent={"center"}>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            fontSize: FontFormat.Body_MD.fontSize,
                            width: "280px",
                          }}
                        />
                      </FlexContainer>
                    ) : (
                      <FlexContainer
                        $flexDirection={"column"}
                        $alignItems={"center"}
                      >
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            fontSize: FontFormat.Body_MD.fontSize,
                            width: "180px",
                          }}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            fontSize: FontFormat.Body_MD.fontSize,
                            width: "280px",
                          }}
                        />
                      </FlexContainer>
                    )}
                  </div>
                </TextEditorContainer>
              </>
            ) : (
              <>
                <MessageContainer ref={messagerRef}>
                  {ticketMessage.map((item, index) => {
                    const formattedDate = dayjs(item.created_at).format(
                      "D MMM YYYY, h.mm A"
                    )
                    const images = item.message_file.filter((file) =>
                      ["image/png", "image/jpg", "image/jpeg"].includes(
                        file.file_tpye
                      )
                    )
                    const files = item.message_file.filter(
                      (file) =>
                        !["image/png", "image/jpg", "image/jpeg"].includes(
                          file.file_tpye
                        )
                    )
                    return (
                      <MessageCard
                        $backgroundColor={
                          item &&
                          item.bluezebra_user_id !== fetchedUser.id &&
                          item.client_user_id !== fetchedUser.id &&
                          Color.Secondary_Hover
                        }
                        key={index}
                      >
                        <FlexContainer $alignItems={"center"} $gap={"16px"}>
                          {item && item.bluezebra_user !== null && (
                            <>
                              <MessageImageUser
                                src={item.bluezebra_user.profile_image}
                                alt={item.bluezebra_user.name}
                              />
                              <div>
                                <FlexContainer
                                  $alignItems={"center"}
                                  $gap={"12px"}
                                >
                                  <MessageNameUser>
                                    {item.bluezebra_user.name}
                                  </MessageNameUser>
                                  {item &&
                                    item.ticket_role &&
                                    item.ticket_role[0]?.name && (
                                      <StatusBox
                                        $textColor={textColor(
                                          item.ticket_role[0].name
                                        )}
                                        $background={background(
                                          item.ticket_role[0].name
                                        )}
                                      >
                                        {item.ticket_role[0].name}
                                      </StatusBox>
                                    )}
                                </FlexContainer>
                                <P $FontFormat={FontFormat.Label_MD}>
                                  {formattedDate}
                                </P>
                              </div>
                            </>
                          )}
                          {item && item.client_user !== null && (
                            <>
                              <MessageImageUser
                                src={item.client_user.logo_image}
                                alt={item.client_user.name}
                              />
                              <div>
                                <FlexContainer
                                  $alignItems={"center"}
                                  $gap={"12px"}
                                >
                                  <MessageNameUser>
                                    {item.client_user.name}
                                  </MessageNameUser>
                                </FlexContainer>
                                <P $FontFormat={FontFormat.Label_MD}>
                                  {formattedDate}
                                </P>
                              </div>
                            </>
                          )}
                        </FlexContainer>
                        <MessageText
                          dangerouslySetInnerHTML={{
                            __html: item.message,
                          }}
                        />
                        {item.message_file.length !== 0 && (
                          <SliderContainer>
                            <Carousel
                              className="ticket-message-file"
                              responsive={responsive}
                            >
                              {images.map((img, index) => (
                                  <PreviewImageInMassage
                                    key={index}
                                    src={img.file}
                                    alt={img.file_name}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => onImageClick(images, index)}
                                  />
                                ))}
                            </Carousel>
                          </SliderContainer>
                        )}
                        {item.message_file.length !== 0 && (
                          <SliderContainer>
                            <Carousel
                              className="preview-file ticket-message-file"
                              responsive={responsive}
                            >
                              {files.map((item, index) => (
                                <MessageFilesContainer key={index}>
                                  <DocumentTextIcon
                                    width={48}
                                    height={48}
                                    color={Color.Primary}
                                  />
                                  <FilesBox
                                    href={item.file}
                                    download={item.file_name}
                                    key={index}
                                  >
                                    {item.file_name}
                                  </FilesBox>
                                </MessageFilesContainer>
                              ))}
                            </Carousel>
                          </SliderContainer>
                        )}
                      </MessageCard>
                    )
                  })}
                  {isShowImage && (
                    <div>
                      {renderPopupshowImage(selectedImage, selectedImageIndex)}
                    </div>
                  )}
                </MessageContainer>
                <TextEditorContainer
                  $filter={
                    ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED
                      ? "blur(5px)"
                      : "none"
                  }
                  $pointerEvents={
                    ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED
                      ? "none"
                      : "unset"
                  }
                >
                  <ReactQuill
                    placeholder="Type here......"
                    modules={toolbarOptions}
                    value={formatDate.textEditor}
                    onChange={onTextEditorChange}
                  />
                  {(previewFiles && previewFiles[0]?.name === "") ||
                  previewFiles.length === 0 ? (
                    <div style={{ margin: "0 32px", marginBottom: "32px" }}>
                      <Button
                        label={
                          isSendLoading ? (
                            <CircularProgress
                              size={24}
                              style={{
                                color: Color.Secondary,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            />
                          ) : (
                            "Send"
                          )
                        }
                        color={Color.Secondary}
                        background={Color.Primary}
                        backgroundActive={Color.Primary_Pressed}
                        width={"70px"}
                        style={{ marginLeft: "auto" }}
                        onClick={onSendMessage}
                        disabled={
                          formatDate.textEditor
                            .replace(/<[^>]+>/g, "")
                            .trim() === "" &&
                          formatDate.uploadFiles.length === 0
                        }
                      />
                      <LineContainer>
                        <Line />
                      </LineContainer>
                      {windowSize.width <= 657 ? (
                        <DropFile onClick={onClickUploadFile}>
                          Click here to upload images and files
                        </DropFile>
                      ) : (
                        <>
                          {isUpload ? (
                            <div style={{ textAlign: "center" }}>
                              <CircularProgress
                                size={48}
                                style={{
                                  color: Color.Primary,
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              />
                            </div>
                          ) : (
                            <DropFile
                              onClick={onClickUploadFile}
                              onDrop={handleDrop}
                              onDragOver={handleDragOver}
                            >
                              Drag & Drop <br /> to upload images and files
                            </DropFile>
                          )}
                        </>
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleUploadFile}
                      />
                    </div>
                  ) : (
                    <>
                      <Button
                        label={
                          isSendLoading ? (
                            <CircularProgress
                              size={24}
                              style={{
                                color: Color.Secondary,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            />
                          ) : (
                            "Send"
                          )
                        }
                        color={Color.Secondary}
                        background={Color.Primary}
                        backgroundActive={Color.Primary_Pressed}
                        width={"70px"}
                        style={{ marginLeft: "auto", marginRight: "16px" }}
                        onClick={onSendMessage}
                        disabled={
                          formatDate.textEditor
                            .replace(/<[^>]+>/g, "")
                            .trim() === "" &&
                          formatDate.uploadFiles.length === 0
                        }
                      />
                      <LineContainer>
                        <Line />
                      </LineContainer>
                      <FlexContainer
                        $alignItems={"center"}
                        $gap={"16px"}
                        $padding={"0 16px"}
                        $justifyContent={windowSize.width <= 657 && "center"}
                        style={{ marginBottom: "32px" }}
                      >
                        <SliderContainer>
                          <Carousel
                            className="ticket-message-file"
                            responsive={responsive}
                          >
                            {previewFiles.map((item, index) => {
                              return (
                                <div key={index}>
                                  {item.type === "image/jpeg" ||
                                  item.type === "image/png" ||
                                  item.type === "image/jpg" ? (
                                    <div style={{ position: "relative" }}>
                                      <PreviewImageFile
                                        src={item.url}
                                        alt={item.name}
                                      />
                                      <SolidXCircleIcon
                                        width={"16px"}
                                        height={"16px"}
                                        color={Color.Tertiary}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                        onClick={() => handleDeleteFile(index)}
                                        style={{
                                          cursor: "pointer",
                                          position: "absolute",
                                          top: "0px",
                                          right: "0px",
                                          padding: "2px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <PreviewFilesBox>
                                      <P
                                        $FontFormat={FontFormat.Title_MD}
                                        $textColor={Color.Primary}
                                        style={{
                                          whiteSpace: "nowrap",
                                          width: "180px",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item && item.name}
                                      </P>
                                      <XMarkIcon
                                        width={"16px"}
                                        height={"16px"}
                                        color={
                                          isHovered
                                            ? Color.Primary_Hover
                                            : Color.Primary
                                        }
                                        strokeWidth={3.1}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                        onClick={() => handleDeleteFile(index)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                    </PreviewFilesBox>
                                  )}
                                </div>
                              )
                            })}
                          </Carousel>
                        </SliderContainer>
                        <FlexContainer
                          $justifyContent={"flex-end"}
                          $padding={"0 16px"}
                        >
                          {formatDate &&
                            formatDate.uploadFiles &&
                            formatDate.uploadFiles.length < 10 && (
                              <AddButton>
                                <PlusIcon
                                  width={"24px"}
                                  height={"24px"}
                                  color={
                                    isHovered
                                      ? Color.Primary_Hover
                                      : Color.Primary
                                  }
                                  strokeWidth={2.5}
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                  onClick={() => onClickUploadFile()}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  onChange={handleUploadFile}
                                />
                              </AddButton>
                            )}
                        </FlexContainer>
                      </FlexContainer>
                    </>
                  )}
                </TextEditorContainer>
              </>
            )}
          </FlexContainer>
        </BodyContainer>
      </FlexContainer>
    </Container>
  )
}
