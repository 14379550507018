import {
    XCircleIcon as SolidXCircleIcon,
    PlusCircleIcon,
    EyeIcon as SolidEyeIcon,
    EyeSlashIcon as SolidEyeSlashIcon,
} from "@heroicons/react/24/solid";

export {
    SolidXCircleIcon,
    PlusCircleIcon,
    SolidEyeIcon,
    SolidEyeSlashIcon,
};