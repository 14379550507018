import React, { useState, useEffect, useContext } from "react"
import { firebaseConfig } from "../../services/auth/firebaseConfig"
import {
    getAuth,
    sendPasswordResetEmail,
} from "firebase/auth"
import { ShowNavBarContext } from "../../components/Context"
import { EnvelopeIcon } from "../../util/icons/Outline"
import { Color, FontFormat, Validator, WindowSize } from "../../util"
import { Input, Button } from "../../components"
import { logo_bluezebra_blue } from "../../img"
import { FlexContainer, H2, P } from "../../style/main"
import {
    ImageBackGroundContainer,
    TextContainer,
    LoginContainer,
    Logo,
} from "./styled"
import CircularProgress from "@mui/material/CircularProgress"

export const ForgetPassword = () => {
    const [email, setEmail] = useState("")
    const [isValid, setisValid] = useState(true)
    const [loading, setLoading] = useState(false)
    const [isSend, setIsSend] = useState(false)
    const { setIsShowNavBar } = useContext(ShowNavBarContext)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const windowSize = WindowSize()
    const auth = getAuth(firebaseConfig)

    useEffect(() => {
        setIsShowNavBar(false)
    }, [setIsShowNavBar])

    const onSendForgetPassword = async () => {
        let validate = true

        if (email === "") {
            validate = false
        } else if (!emailRegex.test(email)) {
            validate = false
        }
        setisValid(validate)
        try {
            if (validate) {
                setLoading(true)
                sendPasswordResetEmail(auth, email)
                    .then(() => {
                        setIsSend(true)
                        setLoading(false)
                    })
                    .catch(() => setLoading(false))
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            onSendForgetPassword()
        }
    }
    return (
        <ImageBackGroundContainer>
            <FlexContainer
                $flexDirection={windowSize.width <= 768 && "column"}
                $justifyContent={
                    windowSize.width <= 768 || windowSize.width >= 1921
                        ? "center"
                        : "space-around"
                }
                $width={"100%"}
                $height={"100%"}
                $alignItems={windowSize.width <= 768 && "center"}
                $gap={windowSize.width >= 1921 && "100px"}
                style={{ paddingTop: "30px", paddingBottom: "30px" }}
            >
                <TextContainer>
                    <H2
                        $FontFormat={
                            windowSize.width <= 480
                                ? FontFormat.Title_XL
                                : FontFormat.Heading_XL
                        }
                        $textColor={Color.Tertiary}
                        style={{
                            lineHeight: "1.2",
                            textShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                            position: "relative",
                            zIndex: 1,
                        }}
                    >
                        Welcome to <br />
                        Bluezebra support system
                    </H2>
                    <P
                        $FontFormat={
                            windowSize.width <= 480
                                ? FontFormat.Title_MD
                                : FontFormat.Title_XL
                        }
                        $textColor={Color.Tertiary_Hover}
                        style={{ lineHeight: "2.5", position: "relative", zIndex: 1 }}
                    >
                        Login to your portal
                    </P>
                </TextContainer>
                <LoginContainer>
                    <Logo src={logo_bluezebra_blue} alt="bluezebra_logo" />
                    {isSend ? (
                        <FlexContainer
                            $flexDirection={"column"}
                            $alignItems={"center"}
                            $gap={"16px"}
                        >
                            <EnvelopeIcon width={85} height={85} color={Color.Primary} />
                            <P $FontFormat={FontFormat.Title_MD} $textColor={Color.Tertiary}>
                                We have sent reset password link to your email.
                            </P>
                        </FlexContainer>
                    ) : (
                        <>
                            <FlexContainer
                                $flexDirection={"column"}
                                $gap={"20px"}
                                $margin={"32px 0"}
                                $width={windowSize.width <= 768 && "100%"}
                            >
                                <Input
                                    type="text"
                                    icon={
                                        <EnvelopeIcon
                                            width={24}
                                            height={24}
                                            color={
                                                (!isValid && !email) ||
                                                    (!isValid && !emailRegex.test(email)) ?
                                                    Color.Error : "#000"
                                            }
                                        />
                                    }
                                    label={"Email"}
                                    width={windowSize.width <= 1024 ? "100%" : "386px"}
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    isValid={
                                        (!isValid && !email) ||
                                        (!isValid && !emailRegex.test(email))
                                    }
                                    onKeyDown={handleKeyDown}
                                />
                                {(!isValid && !email) ||
                                    (!isValid && !emailRegex.test(email)) ? (
                                    <P style={Validator.errorStyle}>
                                        Please enter a valid email address.
                                    </P>
                                ) : null}
                            </FlexContainer>
                            <Button
                                color={Color.Secondary}
                                background={Color.Primary}
                                label={
                                    loading ? (
                                        <CircularProgress
                                            size={24}
                                            style={{
                                                color: Color.Secondary,
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                            }}
                                        />
                                    ) : (
                                        "Send"
                                    )
                                }
                                onClick={onSendForgetPassword}
                            />
                        </>
                    )}
                </LoginContainer>
            </FlexContainer>
        </ImageBackGroundContainer>
    )
}