import React, { useRef, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  TicketCard,
  TicketSkeletonCard,
  Input,
  Button,
  Select,
  Pagination,
} from "../../components"
import {
  MagnifyingGlassIcon,
  PlusIcon,
  AdjustmentsVerticalIcon,
} from "../../util/icons/Outline"
import { userInfo } from "../../auth"
import { Color, FontFormat, WindowSize, Enum } from "../../util"
import { searchTicketByClientId } from "../../api"
import { vector_no_ticket_items } from "../../img"
import { TicketsBackground } from "./TicketsBackground"
import { FlexContainer, H2, P } from "../../style/main"
import { Option, OptionLabel } from "./styled"

export const Tickets = () => {
  const [tickets, setTickets] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedStatus, setSelectedStatus] = useState(
    Enum.SELECT_TICKET_STATUS.ALL
  )
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [isShowStatus, setIsShowStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const windowSize = WindowSize()
  const navigate = useNavigate()
  const popupRef = useRef(null)
  const limit = 9

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const user = await userInfo()
        const searchResponse = await searchTicketByClientId(
          user.id,
          searchQuery,
          selectedStatus,
          page,
          limit
        )
        setTickets(searchResponse.data)
        setTotal(searchResponse.total)
        if (searchResponse.total < limit) {
          setPage(1)
        } else {
          let totalPages = Math.ceil(searchResponse.total / limit)
          if (page > totalPages) {
            setPage(totalPages)
          } else {
            setPage(page)
          }
        }
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }
    fetchData()
  }, [searchQuery, selectedStatus, page, limit])
  useEffect(() => {
    if (isShowStatus && windowSize.width >= 768) {
      setIsShowStatus(false)
    }
  }, [isShowStatus, windowSize])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsShowStatus(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])
  const statusOption = [
    { value: 1, label: Enum.SELECT_TICKET_STATUS.ALL },
    { value: 2, label: Enum.SELECT_TICKET_STATUS.IN_PROGRESS },
    { value: 3, label: Enum.SELECT_TICKET_STATUS.PENDING },
    { value: 4, label: Enum.SELECT_TICKET_STATUS.CLOSED },
  ]
  const onSelectChange = (value) => {
    const status = statusOption.find((option) => option.value === value)
    setSelectedStatus(status.label)
  }
  const onPageChange = (e, newPage) => {
    setPage(newPage)
  }
  const renderNavbarForDesktop = () => {
    return (
      <>
        <FlexContainer $width={"40%"}>
          <Input
            width={"70%"}
            variant={"standard"}
            icon={
              <MagnifyingGlassIcon
                width={24}
                height={24}
                color={Color.Secondary}
              />
            }
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </FlexContainer>
        <FlexContainer
          $flexDirection={"column"}
          $alignItems={"center"}
          $width={"20%"}
        >
          <P $FontFormat={FontFormat.Body_MD} $textColor={Color.Secondary}>
            Total :
          </P>
          <P $FontFormat={FontFormat.Heading_MD} $textColor={"#F4C900"}>
            {total}
          </P>
        </FlexContainer>
        <FlexContainer
          $justifyContent={"flex-end"}
          $gap={"32px"}
          $width={"40%"}
        >
          <Button
            color={Color.Primary}
            background={Color.Secondary}
            backgroundActive={Color.Secondary_Hover}
            height={"46px"}
            label={
              <>
                <PlusIcon width={20} height={20} strokeWidth={2.7} /> Create
              </>
            }
            onClick={() => {
              navigate(`/tickets/create`)
            }}
          />
          <Select
            onSelectChange={onSelectChange}
            isShowDefaultOption={true}
            optionsData={statusOption}
            width={"232px"}
            height={"26px !important"}
          />
        </FlexContainer>
      </>
    )
  }
  const renderNavbarMobile = () => {
    return (
      <>
        <FlexContainer $width={"40%"}>
          <Input
            width={"70%"}
            minWidth={"auto"}
            variant={"standard"}
            icon={
              <MagnifyingGlassIcon
                width={24}
                height={24}
                color={Color.Secondary}
              />
            }
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </FlexContainer>
        <FlexContainer
          $flexDirection={"column"}
          $alignItems={"center"}
          $width={"20%"}
        >
          <P $FontFormat={FontFormat.Label_MD} $textColor={Color.Secondary}>
            Total :
          </P>
          <P $FontFormat={FontFormat.Title_SM} $textColor={"#F4C900"}>
            {total}
          </P>
        </FlexContainer>
        <FlexContainer
          $justifyContent={"flex-end"}
          $gap={"12px"}
          $width={"40%"}
        >
          <PlusIcon
            width={20}
            height={20}
            strokeWidth={2.7}
            color={Color.Secondary}
            onClick={() => {
              navigate(`/tickets/create`)
            }}
          />
          <div ref={popupRef}>
            <AdjustmentsVerticalIcon
              width={20}
              height={20}
              color={Color.Secondary}
              onClick={() => setIsShowStatus(!isShowStatus)}
            />
            {isShowStatus && (
              <Option>
                {statusOption.map((item, index) => (
                  <OptionLabel
                    key={index}
                    $status={selectedStatus === item.label}
                    onClick={() => {
                      setSelectedStatus(item.label)
                      setIsShowStatus(false)
                    }}
                  >
                    {item.label}
                  </OptionLabel>
                ))}
              </Option>
            )}
          </div>
        </FlexContainer>
      </>
    )
  }
  return (
    <TicketsBackground>
      <FlexContainer
        $justifyContent={"space-between"}
        $alignItems={windowSize.width <= 768 ? "center" : "flex-start"}
      >
        {windowSize.width <= 768
          ? renderNavbarMobile()
          : renderNavbarForDesktop()}
      </FlexContainer>
      <FlexContainer
        $flexWrap={"wrap"}
        $justifyContent={
          windowSize.width <= 768 || tickets.length === 0
            ? "center"
            : "flex-start"
        }
        $margin={"40px 0"}
        $gap={windowSize.width > 1920 && "14px"}
      >
        {loading ? (
          <>
            {Array.from({ length: 9 }).map((_, index) => (
              <TicketSkeletonCard key={index} />
            ))}
          </>
        ) : (
          <>
            {tickets && tickets.length === 0 ? (
              <FlexContainer
                $flexDirection={"column"}
                $alignItems={"center"}
                $justifyContent={"center"}
                $height={"auto"}
                $minHeight={"1200px"}
                $gap={"24px"}
              >
                <img
                  src={vector_no_ticket_items}
                  alt="no items found"
                  style={{ width: "100%" }}
                />
                <div style={{ textAlign: "center" }}>
                  <H2
                    $FontFormat={
                      windowSize.width <= 768
                        ? FontFormat.Title_MD
                        : FontFormat.Heading_MD
                    }
                  >
                    No Items Found
                  </H2>
                  <P
                    $FontFormat={
                      windowSize.width <= 768
                        ? FontFormat.Label_MD
                        : FontFormat.Body_MD
                    }
                  >
                    We can’t find any item matching your search.
                  </P>
                </div>
              </FlexContainer>
            ) : (
              <>
                {tickets.map((item, index) => {
                  return <TicketCard key={index} ticket={item} />
                })}
                <Pagination
                  count={total}
                  page={page}
                  limit={limit}
                  onChange={onPageChange}
                />
              </>
            )}
          </>
        )}
      </FlexContainer>
    </TicketsBackground>
  )
}
