import styled from "styled-components"
import { Link } from "react-router-dom"
import { Color, FontFormat } from "../../util"
import { background_ticket_page } from "../../img"

export const Background = styled.div`
  width: 1400px;
  height: auto;
  min-height: 1200px;
  padding: 32px;
  margin: 60px 16px;
  border-radius: 33px;
  overflow: hidden;
  background-image: url(${background_ticket_page});
  background-repeat: no-repeat;
  background-color: ${Color.Light_Blue};
  background-size: contain;
  background-position: top center;
  @media screen and (max-width: 600px) {
    background-size: auto 200px;
    padding: 16px;
    margin: 16px;
  }
`
export const Option = styled.div`
  position: absolute;
  top: 230px;
  right: 40px;
  background-color: ${Color.Secondary};
  color: ${Color.Tertiary};
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  border: 1px solid ${Color.Primary};
  @media screen and (max-width: 600px) {
    top: 220px;
    right: 30px;
  }
`
export const OptionLabel = styled.p`
  cursor: pointer;
  padding: 6px 21px;
  background-color: ${(props) => props.$status && `rgba(25, 118, 210, 0.08)`};
`
export const TimeLine = styled.div`
  width: 100%;
  min-width: 90px;
  ${'' /* width: 247px; */}

  height: 12px;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
  box-shadow: 0px 4px 4px 0px ${Color.Baby_Blue};
  ${'' /* @media screen and (max-width: 1400px) {
    width: 200px;
  } */}
  @media screen and (max-width: 480px) {
    width: 54px;
    min-width: 54px;
  }
`
export const NextButton = styled(Link)`
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  width: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 8px;
  text-decoration: none;
  gap: 8px;
  color: ${Color.Secondary};
  background-color: ${Color.Primary};
  &:hover {
    background-color: ${Color.Primary_Hover};
  }
  &:active,
  &:focus {
    background-color: ${Color.Primary_Pressed};
  }
  @media screen and (max-width: 479px) {
    width: 50%;
  }
  @media screen and (max-width: 299px) {
    width: auto;
  }
`
export const PreviousButton = styled(Link)`
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  width: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 8px;
  text-decoration: none;
  gap: 8px;
  border: 1px solid ${Color.Primary};
  color: ${Color.Primary};
  background-color: ${Color.Secondary};
  &:hover {
    background-color: ${Color.Secondary_Hover};
  }
  @media screen and (max-width: 479px) {
    width: 50%;
  }
  @media screen and (max-width: 299px) {
    width: auto;
  }
`
export const DescriptionBox = styled.textarea`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  width: 100%;
  height: 220px;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.$borderColor};
  background-color: ${Color.Secondary};
  &:active,
  &:focus {
    border: 1px solid ${Color.Primary_Pressed};
  }
  &:focus-visible {
    outline-color: ${Color.Primary};
  }
`
export const PreviewFilesBox = styled.div`
  display: flex !important;
  align-items: center;
  gap: 12px;
  width: 200px !important;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid ${Color.Primary};
  background-color: ${Color.Secondary};
`
export const Description = styled.p`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  line-height: 150%;
  max-height: ${(props) => props.$maxHeight};
  overflow: hidden;
  position: relative;
`
export const SeeMoreText = styled.p`
  margin: 0;
  cursor: pointer;
  color: ${Color.Tertiary};
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  &:hover {
    color: ${Color.Tertiary_Hover};
  }
  &:active,
  &:focus {
    color: ${Color.Tertiary_Pressed};
  }
`
export const UserAvatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 1px solid ${Color.Secondary};
  object-fit: cover;
  object-position: center;
`
