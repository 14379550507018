import React, { useRef, useState, useEffect, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  EnvelopeIcon,
  LockClosedIcon,
  ArrowRightIcon,
  CloudArrowUpIcon,
  TrashIcon,
  BuildingOfficeIcon,
  PhoneIcon,
  MapPinIcon,
} from "../../../util/icons/Outline"
import { ShowNavBarContext } from "../../../components/Context"
import {
  vector_create_user,
  vector_account_is_already_created,
} from "../../../img"
import {
  getClientUserByVerifyId,
  createEmailAndPasswordClientUser,
  updateClientUserByClientId,
} from "../../../api"
import { Error } from "../../index"
import { Color, FontFormat, Validator, WindowSize } from "../../../util"
import { FlexContainer, H2, P } from "../../../style/main"
import { Input, Button } from "../../../components"
import {
  BackGroundImageContainer,
  BlueBoxContainer,
  Vector,
  VectorAccountIsAlready,
  InputContainer,
  Description,
  ButtonUploadImage,
  ButtonCreateContainer,
  ButtonCreate,
  PreviewImageProfile,
  DeleteProfileImage,
} from "./styled"
import CircularProgress from "@mui/material/CircularProgress"

export const CreateProfile = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [formatData, setFormatData] = useState({
    verifyId: "",
    uploadImage: {},
    companyName: "",
    description: "",
    phone: "",
    location: "",
  })
  const [clientId, setClientId] = useState("")
  const [isVerify, setIsVerify] = useState(false)
  const [previewProfileImage, setPreviewProfileImage] = useState("")
  const [currentStep, setCurrentStep] = useState(1)
  const [emailIsAlready, setEmailIsAlready] = useState(false)
  const [isValid, setisValid] = useState(true)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setIsShowNavBar } = useContext(ShowNavBarContext)
  const { verifyId } = useParams()
  const windowSize = WindowSize()
  const navigate = useNavigate()
  const fileInputRef = useRef()
  const uploadRef = useRef(null)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const phoneRegex = /^\+?[0-9\s-]+$/
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/
  const maxLengthDescription = 150
  useEffect(() => {
    getClientUserByVerifyId(verifyId)
      .then((res) => {
        setFormatData((prevState) => ({
          ...prevState,
          verifyId: res.verify_id,
        }))
        setClientId(res.client_user_id)
        setIsVerify(res.is_verify)
      })
      .catch(() => setIsError(true))
  }, [verifyId])
  useEffect(() => {
    setIsShowNavBar(false)
  }, [setIsShowNavBar])
  const handleButtonClickUploadImage = () => {
    fileInputRef.current.click()
  }
  const handleUploadeImage = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setFormatData((prevState) => ({
        ...prevState,
        uploadImage: selectedFile,
      }))
      const reader = new FileReader()

      reader.onloadend = () => {
        setPreviewProfileImage(reader.result)
      }

      reader.readAsDataURL(selectedFile)
    }
  }
  const onDescriptionChange = (event) => {
    const { value } = event.target
    if (value.length <= maxLengthDescription) {
      setFormatData((prevState) => ({
        ...prevState,
        description: value ? value : "",
      }))
    }
  }
  const onDeleteProfileImage = () => {
    setPreviewProfileImage("")
    setFormatData((prevState) => ({
      ...prevState,
      uploadImage: {},
    }))
  }
  const onClearForm = () => {
    setEmail("")
    setPassword("")
    setConfirmPassword("")
    setFormatData({
      verifyId: "",
      uploadImage: {},
      companyName: "",
      description: "",
      phone: "",
      location: "",
    })
    setisValid(false)
    setEmailIsAlready(false)
  }
  const onSave = async () => {
    let validate = true
    if (!email || !password || !confirmPassword) {
      validate = false
    } else if (!emailRegex.test(email)) {
      validate = false
    } else if (!passwordRegex.test(password)) {
      validate = false
    } else if (password !== confirmPassword) {
      validate = false
    }

    setisValid(validate)
    if (validate) {
      try {
        const data = {
          email: email,
          password: password,
        }
        setLoading(true)
        await createEmailAndPasswordClientUser(clientId, data)
        setCurrentStep((prevStep) => Math.min(prevStep + 1, 2))
        setLoading(false)
      } catch (e) {
        if (e.response.data === "email-already") {
          setEmailIsAlready(true)
        } else {
          console.log('error: ', e);
        }
      } finally {
        setLoading(false)
      }
    }
  }
  const onUpdate = () => {
    const { verifyId, uploadImage, companyName, description, phone, location } =
      formatData
    let validate = true

    if (!companyName || !phone || !previewProfileImage) {
      validate = false
    } else if (!phoneRegex.test(formatData.phone)) {
      validate = false
    }

    setisValid(validate)
    let data = {
      verifyId: verifyId,
      name: companyName,
      description: description,
      phone: phone,
      location: location,
    }
    const formdata = new FormData()
    formdata.append("data", JSON.stringify(data))
    formdata.append("image", uploadImage)
    if (validate) {
      try {
        setLoading(true)
        updateClientUserByClientId(clientId, formdata)
          .then(() => {
            navigate("/login")
            onClearForm()
            setLoading(false)
          })
          .catch((e) => {
            setLoading(false)
            console.log(e)
          })
      } catch (e) {
        console.log(e)
      }
    }
  }
  const RenderCreateAccountForm = () => {
    return (
      <BackGroundImageContainer>
        <BlueBoxContainer $height={windowSize.height}>
          <FlexContainer
            $flexDirection={windowSize.width <= 480 && "column"}
            $alignItems={"center"}
            $height={"100%"}
            $gap={
              windowSize.width <= 768 && windowSize.width >= 480
                ? "50px"
                : windowSize.width <= 1200 && windowSize.width >= 769
                  ? "25px"
                  : windowSize.width > 1920
                    ? "50px"
                    : ""
            }
          >
            <FlexContainer
              $flexDirection={"column"}
              $alignItems={"center"}
              $gap={"50px"}
              $width={
                windowSize.width <= 480
                  ? "100%"
                  : windowSize.width <= 1200
                    ? "50%"
                    : "55%"
              }
            >
              <Vector src={vector_create_user} alt="create user vector" />
              <div
                style={{
                  textAlign: windowSize.width <= 480 ? "center" : "right",
                }}
              >
                <H2
                  $FontFormat={
                    windowSize.width <= 768
                      ? FontFormat.Title_XL
                      : windowSize.width <= 1024
                        ? FontFormat.Heading_MD
                        : FontFormat.Heading_XL
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  Let’s Create Profile
                </H2>
                <P
                  $FontFormat={
                    windowSize.width <= 768
                      ? FontFormat.Label_MD
                      : windowSize.width <= 1024
                        ? FontFormat.Body_SM
                        : FontFormat.Body_MD
                  }
                >
                  Enter personal details to your account
                </P>
              </div>
            </FlexContainer>
            <FlexContainer
              $flexDirection={"column"}
              $alignItems={"center"}
              $gap={"16px"}
              $padding={"32px 0"}
              $width={
                windowSize.width <= 480
                  ? "100%"
                  : windowSize.width <= 1200
                    ? "50%"
                    : "45%"
              }
            >
              <H2
                $FontFormat={FontFormat.Title_XL}
                style={{ whiteSpace: "nowrap" }}
              >
                Create Profile
              </H2>
              {currentStep === 1 ? (
                <>
                  <InputContainer>
                    <Input
                      type="text"
                      icon={<EnvelopeIcon width={24} height={24} />}
                      label={"Email"}
                      width={windowSize.width <= 1024 ? "100%" : "386px"}
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      isValid={
                        (!isValid && !email) ||
                        (!isValid && !emailRegex.test(email)) ||
                        emailIsAlready
                      }
                    />
                    {(!isValid && !email) ||
                      (!isValid && !emailRegex.test(email)) ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Please enter a valid email address.
                      </P>
                    ) : emailIsAlready ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Email address is already.
                      </P>
                    ) : null}
                  </InputContainer>
                  <InputContainer>
                    <Input
                      type="password"
                      icon={<LockClosedIcon width={24} height={24} />}
                      label={"Password"}
                      width={windowSize.width <= 1024 ? "100%" : "386px"}
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      isValid={(!isValid && !password) || !isValid && !passwordRegex.test(password)}
                    />
                    {!isValid && !password ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Please enter a valid password address.
                      </P>
                    ) : !isValid && !passwordRegex.test(password) ? (
                      <P style={{ ...Validator.errorStyle, top: 0, width: 386 }}>
                        Must be at least 8 characters including A-Z, a-z, 0-9 and a special character
                      </P>
                    ) : null}
                  </InputContainer>
                  <InputContainer>
                    <Input
                      type="password"
                      icon={<LockClosedIcon width={24} height={24} />}
                      label={"Confirm password"}
                      width={windowSize.width <= 1024 ? "100%" : "386px"}
                      name="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      isValid={(!isValid && !confirmPassword) || !isValid && !passwordRegex.test(password) || password !== confirmPassword}
                    />
                    {!isValid && !confirmPassword ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Please enter a valid Confirm password address.
                      </P>
                    ) : !isValid && password !== confirmPassword ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Password and Confirm Password do not match.
                      </P>
                    ) : !isValid && !passwordRegex.test(password) ? (
                      <P style={{ ...Validator.errorStyle, top: 0, width: 386 }}>
                        Must be at least 8 characters including A-Z, a-z, 0-9 and a special character
                      </P>
                    ) : null}
                  </InputContainer>
                  <Button
                    color={Color.Secondary}
                    background={Color.Primary}
                    label={
                      loading ? (
                        <CircularProgress
                          size={24}
                          style={{
                            color: Color.Secondary,
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        />
                      ) : (
                        "Next"
                      )
                    }
                    onClick={onSave}
                    style={{ marginTop: "32px" }}
                  />
                </>
              ) : currentStep === 2 ? (
                <>
                  {!previewProfileImage ? (
                    <InputContainer>
                      <ButtonUploadImage
                        ref={uploadRef}
                        $isValid={!isValid && !previewProfileImage}
                        onClick={handleButtonClickUploadImage}
                      >
                        <CloudArrowUpIcon
                          width={38}
                          height={38}
                          color={
                            !isValid && !previewProfileImage
                              ? Color.Error
                              : Color.Primary
                          }
                        />
                        <P
                          $FontFormat={
                            windowSize.width <= 768
                              ? FontFormat.Title_SM
                              : FontFormat.Title_XL
                          }
                        >
                          Upload Image
                        </P>
                      </ButtonUploadImage>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleUploadeImage}
                      />
                      {!isValid && !previewProfileImage ? (
                        <P style={{ ...Validator.errorStyle, top: 0 }}>
                          Please select your image.
                        </P>
                      ) : null}
                    </InputContainer>
                  ) : (
                    <div style={{ position: "relative" }}>
                      <DeleteProfileImage onClick={onDeleteProfileImage}>
                        <TrashIcon width={16} height={16} />
                      </DeleteProfileImage>
                      <PreviewImageProfile
                        src={previewProfileImage}
                        alt="preview_imae"
                      />
                    </div>
                  )}
                  <InputContainer>
                    <Input
                      type="text"
                      icon={<BuildingOfficeIcon width={24} height={24} />}
                      label={"Company name*"}
                      width={windowSize.width <= 1024 ? "100%" : "386px"}
                      value={formatData.companyName}
                      onChange={(e) =>
                        setFormatData((prevState) => ({
                          ...prevState,
                          companyName: e.target.value,
                        }))
                      }
                      isValid={!isValid && !formatData.companyName}
                    />
                    {!isValid && !formatData.companyName ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Please enter a valid Company name.
                      </P>
                    ) : null}
                  </InputContainer>
                  <FlexContainer
                    $flexDirection={"column"}
                    $width={windowSize.width <= 1024 && "100%"}
                  >
                    <P $FontFormat={FontFormat.Title_MD}>Description</P>
                    <Description
                      value={formatData.description}
                      onChange={onDescriptionChange}
                      maxLength={maxLengthDescription}
                      $borderColor={Color.Disabled}
                    />
                    <P
                      $FontFormat={FontFormat.Label_MD}
                      $textColor={Color.Primary}
                      $width={windowSize.width <= 1024 ? "100%" : "386px"}
                      $textAlign={"right"}
                      $padding={"8px 0"}
                    >
                      {maxLengthDescription - formatData.description.length}{" "}
                      characters left
                    </P>
                  </FlexContainer>
                  <InputContainer>
                    <Input
                      type="email"
                      icon={<EnvelopeIcon width={24} height={24} />}
                      label={"Email*"}
                      width={windowSize.width <= 1024 ? "100%" : "386px"}
                      value={email}
                      disabled={true}
                    />
                    {(!isValid && !email) ||
                      (!isValid && !emailRegex.test(email)) ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Please enter a valid email address.
                      </P>
                    ) : null}
                  </InputContainer>
                  <InputContainer>
                    <Input
                      type="text"
                      icon={<PhoneIcon width={24} height={24} />}
                      label={"Phone*"}
                      width={windowSize.width <= 1024 ? "100%" : "386px"}
                      value={formatData.phone}
                      onChange={(e) =>
                        setFormatData((prevState) => ({
                          ...prevState,
                          phone: e.target.value,
                        }))
                      }
                      isValid={(!isValid && !formatData.phone) || !isValid && !phoneRegex.test(formatData.phone)}
                    />
                    {(!isValid && !formatData.phone) ||
                      (!isValid && !phoneRegex.test(formatData.phone)) ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Please enter a valid Phone.
                      </P>
                    ) : null}
                  </InputContainer>
                  <Input
                    type="text"
                    icon={<MapPinIcon width={24} height={24} />}
                    label={"Location"}
                    width={windowSize.width <= 1024 ? "100%" : "386px"}
                    value={formatData.location}
                    onChange={(e) =>
                      setFormatData((prevState) => ({
                        ...prevState,
                        location: e.target.value,
                      }))
                    }
                  />
                  <ButtonCreateContainer onClick={onUpdate}>
                    <ButtonCreate>
                      {loading ? (
                        <CircularProgress
                          size={24}
                          style={{
                            color: Color.Secondary,
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            margin: "0 25%",
                          }}
                        />
                      ) : (
                        "Create profile"
                      )}
                    </ButtonCreate>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45"
                      height="48"
                      viewBox="0 0 45 48"
                      fill="none"
                      style={{ position: "absolute", right: "0" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.6776 48C1.68353 40.7702 0 32.621 0 24C0 15.379 1.68353 7.22975 4.6776 0H37C41.4183 0 45 3.58172 45 8V40C45 44.4183 41.4183 48 37 48H4.6776Z"
                        fill="#303269"
                      />
                    </svg>
                    <FlexContainer $alignItems={"center"} $height={"47px"}>
                      <ArrowRightIcon
                        width={"24px"}
                        height={"24px"}
                        color={"#FFF"}
                        style={{
                          position: "absolute",
                          right: "0",
                          paddingRight: "8px",
                        }}
                      />
                    </FlexContainer>
                  </ButtonCreateContainer>
                </>
              ) : null}
            </FlexContainer>
          </FlexContainer>
        </BlueBoxContainer>
      </BackGroundImageContainer>
    )
  }
  const RenderAccountIsAlready = () => {
    return (
      <FlexContainer
        $flexDirection={"column"}
        $alignItems={"center"}
        $justifyContent={"center"}
        $gap={windowSize.width <= 480 ? "40px" : "52px"}
        $height={"100vh"}
        $overflow={windowSize.width <= 480 && "hidden"}
      >
        <VectorAccountIsAlready
          src={vector_account_is_already_created}
          alt="account is already created"
        />
        <div style={{ textAlign: "center" }}>
          <H2
            $FontFormat={
              windowSize.width <= 480
                ? FontFormat.Title_MD
                : FontFormat.Heading_MD
            }
            $textColor={Color.Tertiary_Hover}
            $whiteSpace={"nowrap"}
          >
            Thanks for signing up!
          </H2>
          <P
            $FontFormat={
              windowSize.width <= 480 ? FontFormat.Label_MD : FontFormat.Body_MD
            }
            $textColor={Color.Tertiary}
            $whiteSpace={"nowrap"}
          >
            your account is already created
          </P>
        </div>
      </FlexContainer>
    )
  }
  const RenderError = () => {
    return <Error />
  }
  return (
    <>
      {isError
        ? RenderError()
        : isVerify
          ? RenderAccountIsAlready()
          : RenderCreateAccountForm()}
    </>
  )
}
