import styled from "styled-components"
import { Color } from "../../util"

export const Container = styled.div`
  width: 100%;
  background: ${Color.Pastel_Blue};
`
export const LogoClient = styled.div`
  width: 347px;
  height: 347px;
  border-radius: 16px;
  background: url(${(props) => props.$logo}) lightgray 50% / cover no-repeat;
  box-shadow: 6px 6px 21.9px 0px #1e6899;
  position: absolute;
  z-index: 1;
  @media (max-width: 1200px) {
    width: calc(143px * 1.5);
    height: calc(144px * 1.5);
  }
  @media (max-width: 768px) {
    width: 143px;
    height: 144px;
  }
`
export const Background = styled.img`
  @media (max-width: 1200px) {
    width: calc(202px * 1.5);
    height: calc(185px * 1.5);
  }
  @media (max-width: 768px) {
    width: 202px;
    height: 185px;
  }
`
export const ProductImage = styled.img`
  width: 200px;
  height: 230px;
  border-radius: 12px;
`
export const ProductCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width: 250px;
  height: 350px;
  padding: 20px;
  border-radius: 20px;
  background-color: ${Color.Secondary};
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: 480px) {
    width: 270px;
  }
`
export const DescriptionContainer = styled.div`
  width: 100%;
  overflow: hidden;
  @media (max-width: 480px) {
    text-align: center;
    padding: 12px 0;
  }
`
