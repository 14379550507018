import React, { useState, useEffect } from "react"
import { Color, FontFormat } from "../../util"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"

export const Input = ({
  type,
  width,
  minWidth,
  label,
  placeholder,
  icon,
  variant,
  value,
  onChange,
  onClick,
  isValid,
  onKeyDown,
  readOnly,
  disabled,
}) => {
  const [data, setData] = useState("")
  const [validate, setValidate] = useState(true)

  useEffect(() => {
    if (isValid !== undefined) {
      setValidate(isValid)
    }
    if (value !== "" || value !== undefined) {
      setData(value)
    }
  }, [value, isValid])

  const textFieldSX = {
    width: "100%",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: !disabled && Color.Primary_Hover,
    },
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      backgroundColor: !variant && Color.Secondary,
    },
    "& .MuiInputBase-input": {
      padding: "10px 12px",
      color: variant === "standard" ? Color.Secondary : Color.Primary,
      fontFamily: "IBM Plex Sans Thai, sans-serif",
      fontSize: FontFormat.Title_MD.fontSize,
      fontWeight: FontFormat.Title_MD.fontWeight,
    },
    "& .MuiInputBase-input::placeholder": {
      color: Color.Tertiary,
    },
    "& .MuiFormLabel-root": {
      color: isValid !== undefined && validate ? Color.Error : Color.Tertiary,
      fontFamily: "IBM Plex Sans Thai, sans-serif",
      fontSize: FontFormat.Title_MD.fontSize,
      fontWeight: FontFormat.Title_MD.fontWeight,
      top: !data && !icon ? "-4px" : 0,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: Color.Tertiary,
      top: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        isValid !== undefined && validate ? Color.Error : Color.Disabled,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: Color.Primary_Pressed,
      borderWidth: 1,
    },
    "& .MuiInput-root::before": {
      borderBottom: variant === "standard" && "1px solid #DBF4FE !important"
    },
    "& .MuiInput-root.Mui-focused:after": {
      borderBottom: variant === "standard" && "1px solid #FAFAFA"
    }
  }

  return (
    <Box sx={{ minWidth: minWidth ? minWidth : 120, width: width }}>
      <TextField
        id="outlined-basic"
        type={type}
        label={label}
        placeholder={placeholder}
        variant={variant ? variant : "outlined"}
        sx={textFieldSX}
        value={value}
        InputProps={{
          startAdornment: icon,
          readOnly: readOnly,
        }}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
      />
    </Box>
  )
}
