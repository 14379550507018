import background_login_page from "./background_login_page.svg"
import logo_bluezebra_blue from "./logo_bluezebra_blue.svg"
import logo_bluezebra_white from "./logo_bluezebra_white.svg"
import background_create_user_page from "./background_create_user_page.svg"
import vector_create_user from "./vector_create_user.svg"
import vector_account_is_already_created from "./vector_account_is_already_created.svg"
import background_logo_client from "./background_logo_client.svg"
import logo_bluezebra_no_network from "./logo_bluezebra_no_network.jpg"
import background_ticket_page from "./background_ticket_page.svg"
import vector_no_ticket_items from "./vector_no_ticket_items.svg"
import background_error from "./background_error.svg"
export {
    background_login_page,
    logo_bluezebra_blue,
    logo_bluezebra_white,
    background_create_user_page,
    vector_create_user,
    vector_account_is_already_created,
    background_logo_client,
    logo_bluezebra_no_network,
    background_ticket_page,
    vector_no_ticket_items,
    background_error,
}
