import React from "react"
import {
  FlexContainer,
  BackgroundBlue,
  TitleContainer,
  Title,
  TextCount,
} from "./styled"

export const BackgroundBlueContainer = ({
  title,
  count,
  textCount,
  children,
}) => {
  return (
    <FlexContainer>
      <BackgroundBlue>
        <TitleContainer>
          <Title>{title}</Title>
          {count && textCount ? (
            <TextCount>
              - {count} {textCount}
            </TextCount>
          ) : null}
        </TitleContainer>
        {children}
      </BackgroundBlue>
    </FlexContainer>
  )
}
