import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { SkeletonProfile } from "./skeletonProfile"
import { userInfo } from "../../auth"
import { Color, FontFormat, WindowSize } from "../../util"
import { Button } from "../../components"
import { getClientUserById } from "../../api"
import { background_logo_client } from "../../img"
import { FlexContainer, H2, P } from "../../style/main"
import {
  Container,
  LogoClient,
  ProductCard,
  Background,
  ProductImage,
  DescriptionContainer,
} from "./styled"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./styled.css"

export const Profile = () => {
  const [fetchedUser, setFetchedUser] = useState([])
  const [loading, setLoading] = useState(false)
  const windowSize = WindowSize()
  const navigate = useNavigate()
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1200, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const user = await userInfo()
        const clientUser = await getClientUserById(user.id)
        setFetchedUser(clientUser[0])
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }
    fetchData()
  }, [])
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }
  return (
    <Container>
      {loading ? (
        <SkeletonProfile />
      ) : (
        <>
          <FlexContainer
            $flexDirection={windowSize.width <= 480 ? "column" : "row"}
            $justifyContent={"space-around"}
            $alignItems={"center"}
            $gap={
              windowSize.width <= 768
                ? "20px"
                : windowSize.width <= 1200
                ? "50px"
                : windowSize.width <= 1400
                ? "10px"
                : "100px"
            }
            $padding={
              windowSize.width <= 480
                ? "0 16px"
                : windowSize.width <= 768
                ? "0 16px"
                : windowSize.width <= 1400
                ? "0 48px"
                : "0 15%"
            }
          >
            <FlexContainer
              $justifyContent={"center"}
              $alignItems={"center"}
              $width={
                windowSize.width <= 480
                  ? "100%"
                  : windowSize.width <= 1200
                  ? "unset"
                  : "50%"
              }
              style={{ position: "relative" }}
            >
              <LogoClient $logo={fetchedUser && fetchedUser.logo_image} />
              <Background
                src={background_logo_client}
                alt="background_logo_client"
              />
            </FlexContainer>
            <FlexContainer
              $flexDirection={"column"}
              $alignItems={windowSize.width <= 480 ? "center" : "flex-start"}
              $width={
                windowSize.width <= 480
                  ? "100%"
                  : windowSize.width <= 1200
                  ? "unset"
                  : "50%"
              }
              $gap={windowSize.width <= 768 ? "0px" : "16px"}
            >
              <P
                $FontFormat={FontFormat.Title_MD}
                $textColor={Color.Primary_Hover}
                $lineHeight={"150%"}
              >
                Yours profile
              </P>
              <H2
                $FontFormat={FontFormat.Heading_XL}
                $textColor={Color.Primary_Pressed}
                $lineHeight={"150%"}
              >
                {fetchedUser && fetchedUser.name}
              </H2>
              <DescriptionContainer $width={windowSize.width}>
                <P
                  $FontFormat={FontFormat.Body_MD}
                  $textColor={Color.Tertiary}
                  $lineHeight={"150%"}
                  $overflow={windowSize.width <= 480 && "hidden"}
                  $textOverflow={windowSize.width <= 480 && "ellipsis"}
                  $width={
                    windowSize.width <= 480 && `${windowSize.width - 24}px`
                  }
                  style={{ maxHeight: "7.5em" }}
                >
                  {fetchedUser && fetchedUser.description}
                </P>
              </DescriptionContainer>
              <Button
                color={Color.Secondary}
                background={Color.Primary}
                backgroundActive={Color.Primary_Pressed}
                label={"Edit Profile"}
                onClick={() => {
                  navigate(`/clientUser/update/${fetchedUser.id}`)
                }}
              />
            </FlexContainer>
          </FlexContainer>
          <div
            style={{
              width: windowSize.width > 1400 && "70%",
              margin: windowSize.width > 1400 && "0 auto",
            }}
          >
            {fetchedUser &&
              fetchedUser.purchased_product &&
              fetchedUser.purchased_product.length > 0 && (
                <Carousel
                  className="carousel-purchased-product"
                  responsive={responsive}
                >
                  {fetchedUser.purchased_product.map((item, index) => {
                    return (
                      <FlexContainer
                        $justifyContent={"center"}
                        $padding={"100px 0"}
                        key={index}
                      >
                        <ProductCard>
                          <div>
                            <ProductImage
                              src={item.product.thumbnail_image}
                              alt={item.product.name}
                            />
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <H2
                              $FontFormat={FontFormat.Title_MD}
                              $textColor={Color.Primary_Pressed}
                              $width={"370px"}
                              $whiteSpace={"nowrap"}
                              $overFlow={"hidden"}
                              $textOverflow={"ellipsis"}
                            >
                              {item.product.name}
                            </H2>
                            <P
                              $FontFormat={FontFormat.Body_SM}
                              $textColor={Color.Tertiary}
                              $width={"370px"}
                              $whiteSpace={"nowrap"}
                              $overFlow={"hidden"}
                              $textOverflow={"ellipsis"}
                            >
                              Warranty date : {formatDate(item.end_warranty)}
                            </P>
                          </div>
                        </ProductCard>
                      </FlexContainer>
                    )
                  })}
                </Carousel>
              )}
          </div>
        </>
      )}
    </Container>
  )
}
