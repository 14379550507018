import React, { useRef, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  BackgroundBlueContainer,
  Input,
  Select,
  Button,
} from "../../components"
import {
  ExclamationCircleIcon,
  CloudArrowUpIcon,
  XMarkIcon,
} from "../../util/icons/Outline"
import { userInfo } from "../../auth"
import { Color, FontFormat, Validator, WindowSize } from "../../util"
import {
  getProductOptionByClientId,
  getTicketReferCaseByClientId,
  createTicket,
} from "../../api"
import {
  FlexContainer,
  OverlayContainer,
  PopupContainer,
  H2,
  P,
  Span,
} from "../../style/main"
import {
  TimeLine,
  NextButton,
  PreviousButton,
  DescriptionBox,
  PreviewFilesBox,
  Description,
  SeeMoreText,
  UserAvatar,
} from "./styled"
import Avatar from "@mui/material/Avatar"
import CircularProgress from "@mui/material/CircularProgress"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./styled.css"

export const TicketCreate = () => {
  const [formatDate, setFormatDate] = useState({
    subject: "",
    chooseClient: "",
    chooseProduct: "",
    chooseReferCase: "",
    description: "",
    uploadFiles: [],
  })
  const [clientList, setClientList] = useState([])
  const [productList, setProductList] = useState([])
  const [referCaseList, setReferCaseList] = useState([])
  const [currentStep, setCurrentStep] = useState(1)
  const [previewFiles, setPreviewFiles] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [isHovered, setIsHovered] = useState(false)
  const [isSavePopup, setIsSavePopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef()
  const navigate = useNavigate()
  const windowSize = WindowSize()
  const maxLengthDescription = 2000
  const timeLine = [
    { id: 1, name: "Basic Details", checked: currentStep === 1 },
    {
      id: 2,
      name: "Description",
      checked: currentStep === 2,
    },
    { id: 3, name: "Preview", checked: currentStep === 3 },
    { id: 4, name: "Complete", checked: currentStep === 4 },
  ]
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1701 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1700, min: 1301 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1300, min: 997 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 996, min: 0 },
      items: 1,
    },
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await userInfo()
        if (user && user.id) {
          const optionClients = [
            {
              id: user.id,
              value: 0,
              label: user.name,
              img: user.logo_image,
            },
          ]
          setClientList(optionClients)
          const chooseClient = optionClients[0]
          setFormatDate((prevState) => ({
            ...prevState,
            chooseClient: chooseClient,
          }))

          const products = await getProductOptionByClientId(user.id)
          const optionProducts = products.map((item, index) => ({
            id: item.id,
            value: index,
            label: item.name,
          }))
          setProductList(optionProducts)

          const referCase = await getTicketReferCaseByClientId(user.id)
          const optionReferCase = referCase.map((item, index) => ({
            id: item.id,
            value: index + 2,
            label: item.title,
          }))
          optionReferCase.push({
            id: 1,
            value: 1,
            label: 'None',
          })
          optionReferCase.sort((a, b) => a.value - b.value)
          setReferCaseList(optionReferCase)
        }
      } catch (e) {
        console.error(e)
      }
    }

    fetchData()
  }, [])
  const onSelectProductChange = (value) => {
    const chooseProduct = productList.find((option) => option.value === value)
    setFormatDate((prevState) => ({
      ...prevState,
      chooseProduct: chooseProduct ? chooseProduct : "",
    }))
  }
  const onSelectReferCaseChange = (value) => {
    const chooseReferCase = referCaseList.find(
      (option) => option.value === value
    )
    if (value === 1) { 
      setFormatDate((prevState) => ({
        ...prevState,
        chooseReferCase: "",
      }))
    } else {
      setFormatDate((prevState) => ({
        ...prevState,
        chooseReferCase: chooseReferCase ? chooseReferCase : "",
      }))
    }
  }
  const onDescriptionChange = (event) => {
    const { value } = event.target
    if (value.length <= maxLengthDescription) {
      setFormatDate((prevState) => ({
        ...prevState,
        description: value ? value : "",
      }))
    }
  }
  const onClearForm = () => {
    setFormatDate({
      subject: "",
      chooseClient: "",
      chooseProduct: "",
      chooseReferCase: "",
      description: "",
      uploadFiles: [],
    })
    setExpanded(false)
    setIsValid(false)
  }
  const onSave = () => {
    let validate = true
    if (formatDate.subject === "" || !formatDate.chooseProduct) {
      validate = false
    }
    if (validate) {
      try {
        setLoading(true)
        createTicket(formatDate)
          .then(() => {
            setLoading(false)
            setIsSavePopup(true)
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
          })
      } catch (e) {
        console.log(e)
      }
    }
  }
  const onNext = () => {
    let validate = true
    switch (currentStep) {
      case 1:
        if (formatDate.subject === "" || !formatDate.chooseProduct) {
          validate = false
        }
        break
      case 2:
        if (formatDate.description === "") {
          validate = false
        }
        break
      default:
        break
    }
    setIsValid(validate)
    if (validate) {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, 3))
    }
  }
  const onButtonClickUploadFile = () => {
    fileInputRef.current.click()
  }
  const onUploadFile = (e) => {
    const files = e.target.files
    let selectedFiles = []
    let readers = []
    for (let i = 0; i < files.length && i < 3; i++) {
      const file = files[i]
      if (formatDate.uploadFiles.length < 10) {
        selectedFiles.push(file)
        const reader = new FileReader()
        reader.onloadend = () => {}
        reader.readAsDataURL(file)
        readers.push(reader)
      }
    }

    if (selectedFiles.length > 0) {
      Promise.all(
        readers.map(
          (reader) =>
            new Promise((resolve) => {
              reader.onloadend = () => {
                resolve(reader.result)
              }
            })
        )
      ).then(() => {
        setFormatDate((prevState) => ({
          ...prevState,
          uploadFiles: [...prevState.uploadFiles, ...selectedFiles],
        }))
        setPreviewFiles((prevFiles) => [
          ...prevFiles,
          ...selectedFiles.map((file) => file.name),
        ])
      })
    }
  }
  const onDeleteFile = (index) => {
    setFormatDate((prevState) => ({
      ...prevState,
      uploadFiles: prevState.uploadFiles.filter((file, i) => i !== index),
    }))
    setPreviewFiles((prevFiles) => prevFiles.filter((file, i) => i !== index))
  }
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }
  const renderPopupSave = () => {
    return (
      <>
        <OverlayContainer $top={0} $left={0} $isBlur={true} />
        <PopupContainer>
          <FlexContainer
            $flexDirection={"column"}
            $justifyContent={"center"}
            $alignItems={"center"}
            $gap={"32px"}
            $padding={"40px"}
          >
            <div style={{ textAlign: "center" }}>
              <H2 $textColor={Color.Success_Hover}>Success!</H2>
              <P $textColor={Color.Tertiary}>
                The case has been created completely.
              </P>
            </div>
            <Button
              width={windowSize.width < 480 && "100%"}
              color={Color.Secondary}
              background={Color.Success}
              backgroundActive={Color.Success_Pressed}
              label={"Done"}
              onClick={() => {
                setIsSavePopup(false)
                navigate(`/tickets`)
                onClearForm()
              }}
            />
          </FlexContainer>
        </PopupContainer>
      </>
    )
  }

  return (
    <BackgroundBlueContainer>
      {windowSize.width > 600 && (
        <FlexContainer
          $margin={"0 auto"}
          $padding={"0 0 24px 0"}
          $width={windowSize.width > 600 ? "75%" : "auto"}
        >
          <H2 $FontFormat={FontFormat.Title_XL}>
            Step {currentStep}: {timeLine[currentStep - 1].name}
          </H2>
        </FlexContainer>
      )}
      <FlexContainer $justifyContent={"center"} $width={"100%"}>
        <FlexContainer
          $justifyContent={windowSize.width <= 480 ? "center" :  "space-between"}
          $gap={windowSize.width <= 480 ? "12px" : "28px"}
          $width={windowSize.width > 996 && "75%"}
        >
          {timeLine.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <FlexContainer
                  $flexDirection={"column"}
                  $alignItems={"center"}
                  $gap={"5px"}
                  $width={windowSize.width <= 480 ? "18%" : "23%"}
                >
                  <TimeLine
                    $backgroundColor={
                      item.checked ? Color.Primary_Hover : Color.Disabled
                    }
                  />
                  <P
                    $FontFormat={
                      windowSize.width <= 480
                        ? FontFormat.Label_MD
                        : FontFormat.Body_SM
                    }
                    $textAlign={"center"}
                    $overflow={windowSize.width <= 480 && "hidden"}
                    $width={windowSize.width <= 480 && "90px"}
                    $textOverflow={windowSize.width <= 480 && "ellipsis"}
                    $whiteSpace={"nowrap"}
                  >
                    {item.name}
                  </P>
                </FlexContainer>
              </React.Fragment>
            )
          })}
        </FlexContainer>
      </FlexContainer>
      {currentStep === 1 ? (
        <FlexContainer
          $flexDirection={"column"}
          $justifyContent={"center"}
          $alignItems={"center"}
          $gap={"16px"}
          $padding={"40px 0"}
        >
          <FlexContainer
            $flexDirection={"column"}
            $alignItems={"center"}
            $width={"100%"}
          >
            <Input
              width={windowSize.width > 600 ? "75%" : "100%"}
              type="text"
              label={"Subject"}
              name="Subject"
              value={formatDate.subject}
              onChange={(e) =>
                setFormatDate((prevState) => ({
                  ...prevState,
                  subject: e.target.value,
                }))
              }
              isValid={!isValid && !formatDate.subject}
            />
            {!isValid && formatDate.subject === "" && (
              <FlexContainer
                $alignItems={"center"}
                $gap={"4px"}
                $width={windowSize.width > 600 ? "75%" : "100%"}
              >
                <ExclamationCircleIcon
                  width={"16px"}
                  height={"16px"}
                  color={Color.Error}
                />
                <p style={{ ...Validator.errorStyle, top: 0 }}>
                  Please complete this field.
                </p>
              </FlexContainer>
            )}
          </FlexContainer>
          <FlexContainer
            $flexDirection={"column"}
            $alignItems={"center"}
            $width={"100%"}
          >
            <Select
              isShowDefaultOption={true}
              optionsData={clientList}
              value={formatDate.chooseClient && formatDate.chooseClient.label}
              width={windowSize.width > 600 ? "75%" : "100%"}
              height={"26px !important"}
              label={"Client"}
              isValid={isValid && !formatDate.chooseClient}
              disabled={true}
            />
          </FlexContainer>
          <FlexContainer
            $flexDirection={"column"}
            $alignItems={"center"}
            $width={"100%"}
          >
            <Select
              onSelectChange={onSelectProductChange}
              optionsData={productList}
              value={formatDate.chooseProduct && formatDate.chooseProduct.label}
              width={windowSize.width > 600 ? "75%" : "100%"}
              height={"26px !important"}
              label={"Product"}
              isValid={isValid && !formatDate.chooseProduct}
            />
            {!isValid && !formatDate.chooseProduct && (
              <FlexContainer
                $alignItems={"center"}
                $gap={"4px"}
                $width={windowSize.width > 600 ? "75%" : "100%"}
              >
                <ExclamationCircleIcon
                  width={"16px"}
                  height={"16px"}
                  color={Color.Error}
                />
                <p style={{ ...Validator.errorStyle, top: 0 }}>
                  Please complete this field.
                </p>
              </FlexContainer>
            )}
          </FlexContainer>
          <Select
            onSelectChange={onSelectReferCaseChange}
            optionsData={referCaseList}
            isShowDefaultOption={formatDate.chooseProduct ? formatDate.chooseProduct : false}
            value={
              formatDate.chooseReferCase && formatDate.chooseReferCase.label
            }
            width={windowSize.width > 600 ? "75%" : "100%"}
            height={"26px !important"}
            label={"Refer case"}
          />
        </FlexContainer>
      ) : currentStep === 2 ? (
        <FlexContainer
          $flexDirection={"column"}
          $gap={"8px"}
          $width={windowSize.width > 600 ? "75%" : "auto"}
          $margin={"0 auto"}
          $padding={"40px 0"}
        >
          <FlexContainer $flexDirection={"column"}>
            <P $FontFormat={FontFormat.Title_MD}>Description</P>
            <FlexContainer $justifyContent={"center"}>
              <DescriptionBox
                value={formatDate.description}
                onChange={onDescriptionChange}
                maxLength={maxLengthDescription}
                $borderColor={
                  !isValid && formatDate.description === ""
                    ? Color.Error
                    : Color.Disabled
                }
              />
            </FlexContainer>
            <FlexContainer
              $justifyContent={
                !isValid && formatDate.description === ""
                  ? "space-between"
                  : "flex-end"
              }
            >
              {!isValid && formatDate.description === "" && (
                <FlexContainer $alignItems={"center"} $gap={"4px"}>
                  <ExclamationCircleIcon
                    width={"16px"}
                    height={"16px"}
                    color={Color.Error}
                  />
                  <p style={{ ...Validator.errorStyle, top: 0 }}>
                    Please complete this field.
                  </p>
                </FlexContainer>
              )}
              <P
                $FontFormat={FontFormat.Label_MD}
                $textColor={Color.Primary}
                $textAlign={"right"}
                $padding={"8px 0"}
              >
                {maxLengthDescription - formatDate.description.length}{" "}
                characters left
              </P>
            </FlexContainer>
          </FlexContainer>
          <div>
            <H2 $FontFormat={FontFormat.Title_MD}>
              Attached files{" "}
              <Span $FontFormat={FontFormat.Label_MD} $textColor={Color.Error}>
                *Maximum 10 files
              </Span>
            </H2>
            <Button
              width={windowSize.width < 480 && "100%"}
              color={Color.Secondary}
              background={Color.Primary}
              backgroundActive={Color.Primary_Pressed}
              label={
                <>
                  <CloudArrowUpIcon width={20} height={20} strokeWidth={1.7} />{" "}
                  Upload file
                </>
              }
              isValid={isValid || formatDate.uploadFiles.length <= 10}
              onClick={onButtonClickUploadFile}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={onUploadFile}
            />
            <div style={{ paddingTop: "16px" }}>
              <Carousel className="ticket-create" responsive={responsive}>
                {previewFiles.map((item, index) => {
                  return (
                    <PreviewFilesBox key={index}>
                      <P
                        $FontFormat={FontFormat.Title_MD}
                        $textColor={Color.Primary}
                        style={{
                          whiteSpace: "nowrap",
                          width: "180px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {item}
                      </P>
                      <XMarkIcon
                        width={"16px"}
                        height={"16px"}
                        color={isHovered ? Color.Primary_Hover : Color.Primary}
                        strokeWidth={3.1}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() => onDeleteFile(index)}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </PreviewFilesBox>
                  )
                })}
              </Carousel>
            </div>
          </div>
        </FlexContainer>
      ) : currentStep === 3 ? (
        <>
          {isSavePopup && renderPopupSave()}
          <FlexContainer
            $flexDirection={"column"}
            $gap={"8px"}
            $width={windowSize.width > 600 ? "75%" : "auto"}
            $margin={"0 auto"}
            $padding={"40px 0"}
          >
            {windowSize.width <= 1200 ? (
              <>
                <FlexContainer $alignItems={"flex-start"} $gap={"5px"}>
                  <H2 $FontFormat={FontFormat.Title_MD}>Subject:</H2>
                  <P $FontFormat={FontFormat.Body_MD}>{formatDate.subject}</P>
                </FlexContainer>
                <FlexContainer $alignItems={"center"} $gap={"5px"}>
                  <H2 $FontFormat={FontFormat.Title_MD}>Product:</H2>
                  <P $FontFormat={FontFormat.Body_MD}>
                    {formatDate.chooseProduct.label}
                  </P>
                </FlexContainer>
                <FlexContainer $alignItems={"center"} $gap={"5px"}>
                  <H2 $FontFormat={FontFormat.Title_MD}>Client:</H2>
                  {!formatDate.chooseClient.img ? (
                    <Avatar
                      src={formatDate.chooseClient.img}
                      alt={
                        formatDate.chooseClient.label
                          ? formatDate.chooseClient.img
                          : "image"
                      }
                      style={{ width: "36px", height: "36px" }}
                    />
                  ) : (
                    <UserAvatar
                      src={formatDate.chooseClient.img}
                      alt={formatDate.chooseClient.label}
                    />
                  )}
                  <P $FontFormat={FontFormat.Body_MD}>
                    {formatDate.chooseClient.label}
                  </P>
                </FlexContainer>
                <FlexContainer $alignItems={"center"} $gap={"5px"}>
                  <H2 $FontFormat={FontFormat.Title_MD}>Refer case:</H2>
                  <P $FontFormat={FontFormat.Body_MD}>
                    {!formatDate.chooseReferCase
                      ? "-"
                      : formatDate.chooseReferCase.label}
                  </P>
                </FlexContainer>
                <FlexContainer $flexDirection={"column"} $gap={"5px"}>
                  <H2 $FontFormat={FontFormat.Title_MD}>Description:</H2>
                  <div>
                    <Description $maxHeight={expanded ? "none" : "10.5em"}>
                      {formatDate.description}
                    </Description>
                    {formatDate.description.length > 10.5 * 16 && (
                      <SeeMoreText onClick={toggleExpanded}>
                        {expanded ? "See Less" : "See More"}
                      </SeeMoreText>
                    )}
                  </div>
                </FlexContainer>
              </>
            ) : (
              <>
                <FlexContainer $alignItems={"center"} $gap={"5px"}>
                  <H2 $FontFormat={FontFormat.Title_MD}>Subject:</H2>
                  <P $FontFormat={FontFormat.Body_MD}>{formatDate.subject}</P>
                </FlexContainer>
                <FlexContainer $alignItems={"center"} $gap={"5px"}>
                  <H2 $FontFormat={FontFormat.Title_MD}>Product:</H2>
                  <P $FontFormat={FontFormat.Body_MD}>
                    {formatDate.chooseProduct.label}
                  </P>
                  <P $FontFormat={FontFormat.Body_MD}>|</P>
                  <H2 $FontFormat={FontFormat.Title_MD}>Client:</H2>
                  {!formatDate.chooseClient.img ? (
                    <Avatar
                      src={formatDate.chooseClient.img}
                      alt={
                        formatDate.chooseClient.label
                          ? formatDate.chooseClient.img
                          : "image"
                      }
                      style={{ width: "36px", height: "36px" }}
                    />
                  ) : (
                    <UserAvatar
                      src={formatDate.chooseClient.img}
                      alt={formatDate.chooseClient.label}
                    />
                  )}
                  <P $FontFormat={FontFormat.Body_MD}>
                    {formatDate.chooseClient.label}
                  </P>
                </FlexContainer>
                <FlexContainer $alignItems={"center"} $gap={"5px"}>
                  <H2 $FontFormat={FontFormat.Title_MD}>Refer case:</H2>
                  <P $FontFormat={FontFormat.Body_MD}>
                    {!formatDate.chooseReferCase
                      ? "-"
                      : formatDate.chooseReferCase.label}
                  </P>
                </FlexContainer>
                <FlexContainer $gap={"5px"}>
                  <H2 $FontFormat={FontFormat.Title_MD}>Description:</H2>
                  <div>
                    <Description $maxHeight={expanded ? "none" : "10.5em"}>
                      {formatDate.description}
                    </Description>
                    {formatDate.description.length > 10.5 * 16 && (
                      <SeeMoreText onClick={toggleExpanded}>
                        {expanded ? "See Less" : "See More"}
                      </SeeMoreText>
                    )}
                  </div>
                </FlexContainer>
              </>
            )}
            <div>
              <H2 $FontFormat={FontFormat.Title_MD}>
                Attached files:{" "}
                {previewFiles.length === 0 && (
                  <Span $FontFormat={FontFormat.Body_MD}>-</Span>
                )}
              </H2>
              <div style={{ paddingTop: "8px" }}>
                <Carousel className="ticket-create" responsive={responsive}>
                  {previewFiles.map((item, index) => {
                    return (
                      <PreviewFilesBox key={index}>
                        <P
                          $FontFormat={FontFormat.Title_MD}
                          $textColor={Color.Primary}
                          style={{
                            whiteSpace: "nowrap",
                            width: "180px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {item}
                        </P>
                        <XMarkIcon
                          width={"16px"}
                          height={"16px"}
                          color={
                            isHovered ? Color.Primary_Hover : Color.Primary
                          }
                          strokeWidth={3.1}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          onClick={() => onDeleteFile(index)}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </PreviewFilesBox>
                    )
                  })}
                </Carousel>
              </div>
            </div>
          </FlexContainer>
        </>
      ) : null}
      <FlexContainer
        $flexDirection={windowSize.width > 300 ? "row" : "column"}
        $justifyContent={"center"}
        $gap={windowSize.width > 600 ? "24px" : "12px"}
      >
        {currentStep === 1 && (
          <PreviousButton to={"/tickets"}>Cancel</PreviousButton>
        )}
        {currentStep !== 1 && (
          <PreviousButton
            onClick={() =>
              setCurrentStep((prevStep) => Math.max(prevStep - 1, 1))
            }
          >
            Previous
          </PreviousButton>
        )}
        <NextButton onClick={currentStep === 3 ? onSave : onNext}>
          {currentStep === 3 ? (
            <>
              {loading ? (
                <CircularProgress
                  size={24}
                  style={{
                    color: Color.Secondary,
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                />
              ) : (
                "Submit"
              )}
            </>
          ) : (
            "Next"
          )}
        </NextButton>
      </FlexContainer>
    </BackgroundBlueContainer>
  )
}
