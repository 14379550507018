import React from "react"
import { FlexContainer } from "../../style/main"
import { Background } from "./styled"

export const TicketsBackground = ({ children }) => {
  return (
    <FlexContainer $justifyContent={"center"}>
      <Background>
        {children}
      </Background>
    </FlexContainer>
  )
}
