import React from "react"
import { FontFormat, WindowSize } from "../../util"
import Skeleton from "@mui/material/Skeleton"
import { FlexContainer } from "../../style/main"
import { Container } from "./styled"

export const SkeletonProfile = () => {
  const windowSize = WindowSize()

  return (
    <Container>
      <FlexContainer
        $flexDirection={windowSize.width <= 480 ? "column" : "row"}
        $justifyContent={"space-around"}
        $alignItems={"center"}
        $gap={
          windowSize.width <= 768
            ? "20px"
            : windowSize.width <= 1200
            ? "50px"
            : windowSize.width <= 1400
            ? "10px"
            : "100px"
        }
        $padding={
          windowSize.width <= 480
            ? "0 16px"
            : windowSize.width <= 768
            ? "0 16px"
            : windowSize.width <= 1400
            ? "0 48px"
            : "0 15%"
        }
      >
        <FlexContainer
          $justifyContent={"center"}
          $alignItems={"center"}
          $width={
            windowSize.width <= 480
              ? "100%"
              : windowSize.width <= 1200
              ? "unset"
              : "50%"
          }
          style={{ position: "relative" }}
        >
          <Skeleton
            variant="rounded"
            width={
              windowSize.width <= 768
                ? "143px"
                : windowSize.width <= 1200
                ? `calc(143px * 1.5)`
                : "347px"
            }
            height={
              windowSize.width <= 768
                ? "144px"
                : windowSize.width <= 1200
                ? `calc(144px * 1.5)`
                : "347px"
            }
            style={{
              borderRadius: "16px",
              position: "absolute",
              zIndex: 1,
            }}
          />
          <Skeleton
            variant="rounded"
            width={
              windowSize.width <= 768
                ? "185px"
                : windowSize.width <= 1200
                ? `calc(202px * 1.5)`
                : "536px"
            }
            height={
              windowSize.width <= 768
                ? "185px"
                : windowSize.width <= 1200
                ? `calc(185px * 1.5)`
                : "499px"
            }
            style={{
              borderRadius: "16px",
            }}
          />
        </FlexContainer>
        <FlexContainer
          $flexDirection={"column"}
          $alignItems={"flex-start"}
          $width={
            windowSize.width <= 480
              ? "100%"
              : windowSize.width <= 1200
              ? "unset"
              : "50%"
          }
          $gap={windowSize.width <= 768 ? "0px" : "16px"}
          $overflow={"hidden"}
        >
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: FontFormat.Title_MD.fontSize, width: "180px" }}
          />
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: FontFormat.Heading_XL.fontSize, width: "380px" }}
          />
          <div>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton
                key={index}
                variant="text"
                animation="wave"
                sx={{
                  fontSize: FontFormat.Body_MD.fontSize,
                  width:
                    windowSize.width <= 480
                      ? `${windowSize.width - 24}px`
                      : "480px",
                }}
              />
            ))}
            {Array.from({ length: 2 }).map((_, index) => (
              <Skeleton
                key={index}
                variant="text"
                animation="wave"
                sx={{
                  fontSize: FontFormat.Body_MD.fontSize,
                  width:
                    windowSize.width <= 480
                      ? `${windowSize.width - 24}px`
                      : "280px",
                }}
              />
            ))}
          </div>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={"127px"}
            height={"41px"}
            style={{ borderRadius: "8px" }}
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        $justifyContent={"center"}
        $gap={"100px"}
        $padding={"100px 0"}
      >
        {Array.from({
          length:
            windowSize.width <= 768 ? 1 : windowSize.width <= 1200 ? 2 : 3,
        }).map((_, index) => (
          <Skeleton
            key={index}
            animation="wave"
            variant="rounded"
            width={windowSize.width <= 480 ? "270px" : "250px"}
            height={"350px"}
            style={{
              borderRadius: "20px ",
            }}
          />
        ))}
      </FlexContainer>
    </Container>
  )
}
