import { Enum } from "../util"
import { Axios } from "../util/Axios"

const basedURL = process.env.REACT_APP_API
const accessToken = localStorage.getItem("accessToken")

// Product
export const getProductOptionByClientId = async (clientId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}product/${clientId}`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// ClientUser
export const getClientUserById = async (clientId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}clientUser/detail/${clientId}`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getClientUserByFirebaseId = async (firebaseId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}clientUser/firebase/${firebaseId}`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getClientUserByVerifyId = async (verifyId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}clientUser/${verifyId}`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const createEmailAndPasswordClientUser = async (clientId, body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.PUT,
      `${basedURL}clientUser/create/${clientId}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateClientUserByClientId = async (clientId, body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.FORM_DATA,
      Enum.METHOD.PUT,
      `${basedURL}clientUser/update/${clientId}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Ticket
export const getTicketById = async (ticketId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}ticket/case/${ticketId}`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const searchTicketByClientId = async (clientId, searchQuery, status, page, limit) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}ticket/${clientId}`,
      accessToken,
      { search: searchQuery, status: status, page: page, limit: limit },
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getTicketReferCaseByClientId = async (clientId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}ticket/referCase/${clientId}`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const createTicket = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.FORM_DATA,
      Enum.METHOD.POST,
      `${basedURL}ticket/create`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// TicketMessage
export const getTicketMessageById = async (ticketId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}ticketMessage/${ticketId}`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const createTicketMessage = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.FORM_DATA,
      Enum.METHOD.POST,
      `${basedURL}ticketMessage/create`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}