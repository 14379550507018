import styled from "styled-components"
import { Color, FontFormat } from "../../util"

export const CustomButton = styled.button`
  font-size: ${(props) =>
    props.$fontSize !== undefined
      ? props.$fontSize
      : `${FontFormat.Title_MD.fontSize}`};
  font-weight: ${(props) =>
    props.$fontWeight !== undefined
      ? props.$fontWeight
      : `${FontFormat.Title_MD.fontWeight}`};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  margin: ${(props) => props.$margin};
  display: flex;
  padding: ${(props) => (props.$padding ? props.$padding : "10px 16px")};
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: ${(props) => props.$labelColor};
  border: ${(props) =>
    props.$isBorder ? `1px solid ${props.$borderColor}` : "none"};
  cursor: pointer;
  background-color: ${(props) =>
    props.$isValid !== undefined && !props.$isValid
      ? `${Color.Error}`
      : props.$background};
  &:hover {
    background-color: ${(props) => {
      if (props.$background === Color.Secondary) {
        return Color.Secondary_Hover
      } else if (props.$background === Color.Primary) {
        return Color.Primary_Hover
      } else if (props.$background === Color.Success) {
        return Color.Success_Hover
      } else {
        return props.$background
      }
    }};
    color: ${(props) => {
      if (props.$labelColor === Color.Secondary) {
        return Color.Secondary_Hover
      } else if (props.$labelColor === Color.Primary) {
        return Color.Primary_Hover
      } else if (props.$labelColor === Color.Success) {
        return Color.Success_Hover
      } else {
        return props.$labelColor
      }
    }};
  }
  &:active,
  &:focus {
    background-color: ${(props) => props.$backgroundActive};
    color: ${(props) => props.$labelColorActive};
  }
  &:disabled {
    background-color: ${Color.Disabled};
    cursor: not-allowed;
  }
`
