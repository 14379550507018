import React, { forwardRef } from "react"
import { CustomButton } from "./styled"

export const Button = forwardRef(
  (
    {
      fontSize,
      fontWeight,
      margin,
      padding,
      color,
      colorActive,
      background,
      backgroundActive,
      isBorder,
      borderColor,
      label,
      isValid,
      onClick,
      width,
      height,
      style,
      disabled,
    },
    ref
  ) => {
    return (
      <CustomButton
        ref={ref}
        $fontSize={fontSize}
        $fontWeight={fontWeight}
        $width={width}
        $height={height}
        $margin={margin}
        $padding={padding}
        $labelColor={color}
        $labelColorActive={colorActive}
        $background={background}
        $isBorder={isBorder}
        $borderColor={borderColor}
        $backgroundActive={backgroundActive}
        $isValid={isValid}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        {label}
      </CustomButton>
    )
  }
)
