import React, { useState, useEffect } from "react"
import { useLocation, Link } from "react-router-dom"
import { getAuth, signOut } from "firebase/auth"
import { firebaseConfig } from "../../services/auth/firebaseConfig"
import {
  Bars3Icon,
  XMarkIcon,
  UserIcon,
  FolderIcon,
  ArrowRightStartOnRectangleIcon,
} from "../../util/icons/Outline"
import { Color, WindowSize } from "../../util"
import { logo_bluezebra_blue } from "../../img"
import { FlexContainer } from "../../style/main"
import {
  Logo,
  MenuLink,
  MenuHamburger,
  MenuHamburgerLogout,
  LogoutButton,
  BackgroundBlur,
  CircleIconCntainer,
} from "./styled"

export const NavBar = () => {
  const [isHovered, setIsHovered] = useState(false)
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const auth = getAuth(firebaseConfig)
  const windowSize = WindowSize()
  const location = useLocation()
  const pathname = location.pathname
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden"
      document.documentElement.style.height = "100vh"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.height = ""
    }

    return () => {
      document.body.style.overflow = ""
      document.documentElement.style.height = ""
    }
  }, [isMenuOpen])
  const onLogout = () => {
    signOut(auth)
  }
  return (
    <div>
      <FlexContainer
        $justifyContent={"space-between"}
        $alignItems={"center"}
        $padding={windowSize.width <= 768 ? "0 16px" : "0px 87px"}
        $height={"auto"}
        $overflow={windowSize.width <= 350 && "hidden"}
        style={{
          backgroundColor:
            pathname === "/profile"
              ? Color.Pastel_Blue
              : pathname.includes("/tickets/detail/")
              ? Color.Light_Blue
              : "",
        }}
      >
        <Link to={"/profile"} style={{ height: "107px" }}>
          <Logo src={logo_bluezebra_blue} alt="logo_bluezebra" />
        </Link>
        {windowSize.width <= 480 ? (
          <div style={{ position: "relative" }}>
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              style={{ cursor: "pointer" }}
            >
              {!isShowMenu ? (
                <Bars3Icon
                  width={"32px"}
                  height={"32px"}
                  color={isHovered ? Color.Primary_Hover : Color.Primary}
                  onClick={() => {
                    setIsShowMenu(true)
                    setIsMenuOpen(true)
                  }}
                />
              ) : (
                <XMarkIcon
                  width={"32px"}
                  height={"32px"}
                  color={isHovered ? Color.Primary_Hover : Color.Primary}
                  onClick={() => {
                    setIsShowMenu(false)
                    setIsMenuOpen(false)
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <FlexContainer $gap={windowSize.width <= 768 ? "15px" : "90px"}>
            <FlexContainer
              $alignItems={"center"}
              $gap={windowSize.width <= 768 ? "15px" : "56px"}
            >
              <MenuLink
                to="/profile"
                $textDecoration={pathname !== "/profile" ? "none" : "underline"}
              >
                Profile
              </MenuLink>
              <MenuLink
                to="/tickets"
                $textDecoration={
                  pathname !== "/tickets" &&
                  !pathname.includes("/tickets/detail/")
                    ? "none"
                    : "underline"
                }
              >
                All cases
              </MenuLink>
            </FlexContainer>
            <LogoutButton onClick={onLogout}>Logout</LogoutButton>
          </FlexContainer>
        )}
      </FlexContainer>
      {windowSize.width <= 480 && isShowMenu && (
        <BackgroundBlur>
          <FlexContainer
            $flexDirection={"column"}
            $gap={"24px"}
            $padding={"48px"}
          >
            <FlexContainer
              $alignItems={"center"}
              $gap={"8px"}
              onClick={() => {
                setIsShowMenu(false)
                setIsMenuOpen(false)
              }}
            >
              <CircleIconCntainer>
                <UserIcon
                  width={"28px"}
                  height={"28px"}
                  color={Color.Secondary}
                />
              </CircleIconCntainer>
              <MenuHamburger to="/profile">Profile</MenuHamburger>
            </FlexContainer>
            <FlexContainer
              $alignItems={"center"}
              $gap={"8px"}
              onClick={() => {
                setIsShowMenu(false)
                setIsMenuOpen(false)
              }}
            >
              <CircleIconCntainer>
                <FolderIcon
                  width={"28px"}
                  height={"28px"}
                  color={Color.Secondary}
                />
              </CircleIconCntainer>
              <MenuHamburger to="/tickets">All cases</MenuHamburger>
            </FlexContainer>
            <FlexContainer
              $alignItems={"center"}
              $gap={"8px"}
              onClick={() => {
                onLogout()
                setIsShowMenu(false)
                setIsMenuOpen(false)
              }}
              style={{ position: "relative", zIndex: 999 }}
            >
              <CircleIconCntainer>
                <ArrowRightStartOnRectangleIcon
                  width={"28px"}
                  height={"28px"}
                  color={Color.Secondary}
                />
              </CircleIconCntainer>
              <MenuHamburgerLogout>Logout</MenuHamburgerLogout>
            </FlexContainer>
          </FlexContainer>
        </BackgroundBlur>
      )}
    </div>
  )
}
