import React from "react"
import Skeleton from "@mui/material/Skeleton"
import { FontFormat } from "../../util"
import { FlexContainer } from "../../style/main"
import { Container, CardContainer } from "./styled"

export const TicketSkeletonCard = () => {
  return (
    <Container>
      <CardContainer>
        <FlexContainer
          $alignItems={"center"}
          $justifyContent={"space-between"}
          $width={"100%"}
        >
          <Skeleton variant="rounded" width={95} height={85} animation="wave" />
          <FlexContainer $flexDirection={"column"} $alignItems={"flex-end"}>
            <Skeleton
              variant="text"
              width={120}
              animation="wave"
              sx={{ fontSize: FontFormat.Title_SM.fontSize }}
            />
            <Skeleton
              variant="rounded"
              width={81}
              height={36}
              animation="wave"
            />
          </FlexContainer>
        </FlexContainer>
        <div>
          <Skeleton
            variant="text"
            width={280}
            animation="wave"
            sx={{ fontSize: FontFormat.Title_MD.fontSize }}
          />
          <Skeleton
            variant="text"
            width={280}
            animation="wave"
            sx={{ fontSize: FontFormat.Body_SM.fontSize }}
          />
        </div>
        <div>
          <Skeleton
            variant="text"
            width={280}
            animation="wave"
            sx={{ fontSize: FontFormat.Body_SM.fontSize }}
          />
          <Skeleton
            variant="text"
            width={280}
            animation="wave"
            sx={{ fontSize: FontFormat.Label_MD.fontSize }}
          />
        </div>
      </CardContainer>
    </Container>
  )
}
