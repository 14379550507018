// Default color
const Primary = "#0069AE"
const Secondary = "#FAFAFA"
const Tertiary = "#262626"
const Disabled = "#C4C4C4"
const Success = "#007D64"
const In_Progress = "#F4C900"
const Error = "#C23E5F"

// Hover colors
const Primary_Hover = "#0D7ABF"
const Secondary_Hover = "#ECF0F3"
const Tertiary_Hover = "#434343"
const Success_Hover = "#038D73"
const In_Progress_Hover = "#F5DC00"
const Error_Hover = "#D24263"

// Pressed color
const Primary_Pressed = "#004C8F"
const Secondary_Pressed = "#0069AE"
const Tertiary_Pressed = "#000"
const Success_Pressed = "#006D56"
const In_Progress_Pressed = "#F3B100"
const Error_Pressed = "#AC3959"

// Additional color
const Baby_Blue = "#DBF4FE"
const Light_Blue = "#ECF0F3"
const Pastel_Blue = "#F5F9FD"

export const Color = {
  Primary,
  Primary_Hover,
  Primary_Pressed,
  Secondary,
  Secondary_Hover,
  Secondary_Pressed,
  Tertiary,
  Tertiary_Hover,
  Tertiary_Pressed,
  Disabled,
  Success,
  Success_Hover,
  Success_Pressed,
  In_Progress,
  In_Progress_Hover,
  In_Progress_Pressed,
  Error,
  Error_Hover,
  Error_Pressed,
  Baby_Blue,
  Light_Blue,
  Pastel_Blue,
}