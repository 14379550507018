import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import styled from "styled-components"

export const BoxMui = styled(Box)``
export const FormControlMui = styled(FormControl)``
export const LabelMui = styled(InputLabel)``
export const SelectMui = styled(Select)``
export const OptionMui = styled(MenuItem)``
