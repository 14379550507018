import React, { useState, useEffect } from "react"
import { Navigate } from "react-router-dom"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { firebaseConfig } from "../services/auth/firebaseConfig"

export const PrivateRoute = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false)
  const [checkedAuth, setCheckedAuth] = useState(false)
  const auth = getAuth(firebaseConfig)

  useEffect(() => {
    const checkAuth = () => {
      onAuthStateChanged(auth, (user) => {
        setIsAuth(!!user);
        setCheckedAuth(true);
    })
    };
  
    const unsubscribe = checkAuth();
  
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [auth]);

  return checkedAuth ? (isAuth ? children : <Navigate to="/login" />) : null;
}