import React, { useRef, useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import {
  EnvelopeIcon,
  CloudArrowUpIcon,
  TrashIcon,
  BuildingOfficeIcon,
  PhoneIcon,
  MapPinIcon,
} from "../../../util/icons/Outline"
import { getClientUserById, updateClientUserByClientId } from "../../../api"
import { Input, Loading, Button } from "../../../components"
import { FlexContainer, H2, P } from "../../../style/main"
import { vector_create_user } from "../../../img"
import { Error } from "../../index"
import { Color, FontFormat, Validator, WindowSize } from "../../../util"
import {
  BackGroundImageContainer,
  BlueBoxContainer,
  Vector,
  InputContainer,
  Description,
  ButtonUploadImage,
  PreviewImageProfile,
  DeleteProfileImage,
} from "../createProfile/styled"
import CircularProgress from "@mui/material/CircularProgress"

export const UpdateProfile = () => {
  const [client, setClient] = useState(null)
  const [previewProfileImage, setPreviewProfileImage] = useState("")
  const [formatData, setFormatData] = useState({
    uploadImage: {},
    companyName: "",
    description: "",
    email: "",
    phone: "",
    location: "",
  })
  const [loading, setLoading] = useState(false)
  const [isValid, setisValid] = useState(true)
  const [isError, setIsError] = useState(false)
  const { clientId } = useParams()
  const navigate = useNavigate()
  const windowSize = WindowSize()
  const fileInputRef = useRef()
  const uploadRef = useRef(null)
  const maxLengthDescription = 150

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getClientUserById(clientId)
        const client = user[0]
        setClient(client)
        setPreviewProfileImage(client.logo_image)
        setFormatData({
          uploadImage: client.original_logo_image,
          companyName: client.name,
          description: client.description,
          email: client.email,
          phone: client.phone_number,
          location: client.location,
        })
      } catch (e) {
        setIsError(true)
        console.error(e)
      }
    }

    fetchData()
  }, [clientId])
  const handleButtonClickUploadImage = () => {
    fileInputRef.current.click()
  }
  const handleUploadeImage = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setFormatData((prevState) => ({
        ...prevState,
        uploadImage: selectedFile,
      }))
      const reader = new FileReader()

      reader.onloadend = () => {
        setPreviewProfileImage(reader.result)
      }

      reader.readAsDataURL(selectedFile)
    }
  }
  const onDescriptionChange = (event) => {
    const { value } = event.target
    if (value.length <= maxLengthDescription) {
      setFormatData((prevState) => ({
        ...prevState,
        description: value ? value : "",
      }))
    }
  }
  const onDeleteProfileImage = () => {
    setPreviewProfileImage("")
    setFormatData((prevState) => ({
      ...prevState,
      uploadImage: {},
    }))
  }
  const onClearForm = () => {
    setPreviewProfileImage("")
    setFormatData({
      uploadImage: {},
      companyName: "",
      description: "",
      email: "",
      phone: "",
      location: "",
    })
    setisValid(false)
  }
  const onUpdate = () => {
    const { uploadImage, companyName, description, phone, location } =
      formatData
    let validate = true
    if (!companyName || !phone || !previewProfileImage) {
      validate = false
    }
    setisValid(validate)
    let data = {
      uploadImage: uploadImage,
      name: companyName,
      description: description,
      phone: phone,
      location: location,
    }
    const formdata = new FormData()
    formdata.append("data", JSON.stringify(data))
    formdata.append("image", uploadImage)
    if (validate) {
      try {
        setLoading(true)
        updateClientUserByClientId(clientId, formdata)
          .then(() => {
            navigate("/profile")
            onClearForm()
            setLoading(false)
          })
          .catch((e) => {
            setLoading(false)
            console.log(e)
          })
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <>
      {isError ? (
        <Error />
      ) : !client ? (
        <Loading />
      ) : (
        <BackGroundImageContainer>
          <BlueBoxContainer>
            <FlexContainer
              $flexDirection={windowSize.width <= 480 && "column"}
              $alignItems={"center"}
              $height={"100%"}
              $gap={
                windowSize.width <= 768 && windowSize.width >= 480
                  ? "50px"
                  : windowSize.width <= 1200 && windowSize.width >= 769
                  ? "25px"
                  : windowSize.width > 1920
                  ? "50px"
                  : ""
              }
            >
              <FlexContainer
                $flexDirection={"column"}
                $alignItems={"center"}
                $gap={"50px"}
                $width={
                  windowSize.width <= 480
                    ? "100%"
                    : windowSize.width <= 1200
                    ? "50%"
                    : "55%"
                }
              >
                <Vector src={vector_create_user} alt="create user vector" />
                <div
                  style={{
                    textAlign: windowSize.width <= 480 ? "center" : "right",
                  }}
                >
                  <H2
                    $FontFormat={
                      windowSize.width <= 768
                        ? FontFormat.Title_XL
                        : windowSize.width <= 1024
                        ? FontFormat.Heading_MD
                        : FontFormat.Heading_XL
                    }
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Let’s Edit Profile
                  </H2>
                  <P
                    $FontFormat={
                      windowSize.width <= 768
                        ? FontFormat.Label_MD
                        : windowSize.width <= 1024
                        ? FontFormat.Body_SM
                        : FontFormat.Body_MD
                    }
                  >
                    Enter personal details to your account
                  </P>
                </div>
              </FlexContainer>
              <FlexContainer
                $flexDirection={"column"}
                $alignItems={"center"}
                $gap={"16px"}
                $padding={"32px 0"}
                $width={
                  windowSize.width <= 480
                    ? "100%"
                    : windowSize.width <= 1200
                    ? "50%"
                    : "45%"
                }
              >
                <H2
                  $FontFormat={FontFormat.Title_XL}
                  style={{ whiteSpace: "nowrap" }}
                >
                  Edit Profile
                </H2>
                {!previewProfileImage ? (
                  <InputContainer>
                    <ButtonUploadImage
                      ref={uploadRef}
                      $isValid={!isValid && !previewProfileImage}
                      onClick={handleButtonClickUploadImage}
                    >
                      <CloudArrowUpIcon
                        width={38}
                        height={38}
                        color={
                          !isValid && !previewProfileImage
                            ? Color.Error
                            : Color.Primary
                        }
                      />
                      <P
                        $FontFormat={
                          windowSize.width <= 768
                            ? FontFormat.Title_SM
                            : FontFormat.Title_XL
                        }
                      >
                        Upload Image
                      </P>
                    </ButtonUploadImage>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleUploadeImage}
                    />
                    {!isValid && !previewProfileImage ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Please select your image.
                      </P>
                    ) : null}
                  </InputContainer>
                ) : (
                  <div style={{ position: "relative" }}>
                    <DeleteProfileImage onClick={onDeleteProfileImage}>
                      <TrashIcon width={16} height={16} />
                    </DeleteProfileImage>
                    <PreviewImageProfile
                      src={previewProfileImage}
                      alt="preview_imae"
                    />
                  </div>
                )}
                <InputContainer>
                  <Input
                    type="text"
                    icon={<BuildingOfficeIcon width={24} height={24} />}
                    label={"Company name"}
                    width={windowSize.width <= 1024 ? "100%" : "386px"}
                    value={formatData.companyName}
                    onChange={(e) =>
                      setFormatData((prevState) => ({
                        ...prevState,
                        companyName: e.target.value,
                      }))
                    }
                    isValid={!isValid && !formatData.companyName}
                  />
                  {!isValid && !formatData.companyName ? (
                    <P style={{ ...Validator.errorStyle, top: 0 }}>
                      Please enter a valid Company name.
                    </P>
                  ) : null}
                </InputContainer>
                <FlexContainer
                  $flexDirection={"column"}
                  $width={windowSize.width <= 1024 && "100%"}
                >
                  <P
                    $FontFormat={FontFormat.Title_MD}
                    $padding={"0 16px"}
                    style={{ fontSize: "14px" }}
                  >
                    Description
                  </P>
                  <Description
                    value={formatData.description}
                    onChange={onDescriptionChange}
                    maxLength={maxLengthDescription}
                    $borderColor={Color.Disabled}
                  />
                  <P
                    $FontFormat={FontFormat.Label_MD}
                    $textColor={"#C4C4C4"}
                    $width={windowSize.width <= 1024 ? "100%" : "386px"}
                    $textAlign={"right"}
                    $padding={"8px 0"}
                  >
                    {maxLengthDescription - formatData.description.length}{" "}
                    characters left
                  </P>
                </FlexContainer>
                <InputContainer>
                  <Input
                    type="email"
                    icon={<EnvelopeIcon width={24} height={24} />}
                    label={"Email"}
                    width={windowSize.width <= 1024 ? "100%" : "386px"}
                    value={formatData.email}
                    disabled={true}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    type="text"
                    icon={<PhoneIcon width={24} height={24} />}
                    label={"Phone"}
                    width={windowSize.width <= 1024 ? "100%" : "386px"}
                    value={formatData.phone}
                    onChange={(e) =>
                      setFormatData((prevState) => ({
                        ...prevState,
                        phone: e.target.value,
                      }))
                    }
                    isValid={!isValid && !formatData.phone}
                  />
                  {!isValid && !formatData.phone ? (
                    <P style={{ ...Validator.errorStyle, top: 0 }}>
                      Please enter a valid Phone.
                    </P>
                  ) : null}
                </InputContainer>
                <Input
                  type="text"
                  icon={<MapPinIcon width={24} height={24} />}
                  label={"Location"}
                  width={windowSize.width <= 1024 ? "100%" : "386px"}
                  value={formatData.location}
                  onChange={(e) =>
                    setFormatData((prevState) => ({
                      ...prevState,
                      location: e.target.value,
                    }))
                  }
                />
                <Button
                  color={Color.Secondary}
                  background={Color.Primary}
                  label={
                    loading ? (
                      <CircularProgress
                        size={24}
                        style={{
                          color: Color.Secondary,
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          margin: "0 25%",
                        }}
                      />
                    ) : (
                      "Save"
                    )
                  }
                  onClick={onUpdate}
                />
              </FlexContainer>
            </FlexContainer>
          </BlueBoxContainer>
        </BackGroundImageContainer>
      )}
    </>
  )
}
