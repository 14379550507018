import styled from "styled-components"
import { Link } from "react-router-dom"
import { Color } from "../../util"
import { background_login_page } from "../../img"

export const ImageBackGroundContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url(${background_login_page});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(236, 233, 233, 0.25);
  }
  @media (max-width: 768px) {
    width: auto;
    height: auto;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
  }
`
export const TextContainer = styled.div`
  margin: auto 32px;
  padding-bottom: 200px;
  @media (min-width: 1921px) {
    margin: auto 0;
    padding-bottom: 300px;
  }
  @media (max-width: 1024px) {
    white-space: nowrap;
  }
  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    text-align: center;
  }
`
export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  margin: auto 0;
  width: 550px;
  height: 600px;
  border-radius: 16px;
  background-color: ${Color.Secondary};
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 999;
  @media (min-width: 1921px) {
  }
  @media (max-width: 1024px) {
    margin: auto 32px;
  }
  @media (max-width: 996px) {
    height: auto;
  }
  @media (max-width: 768px) {
    width: 70%;
    height: 600px;
    padding: 32px;
    margin: 16px;
  }
  @media (max-width: 480px) {
    width: 85%;
    height: 60%;
    padding: 16px;
    margin: 16px;
  }
  @media (max-width: 300px) {
    overflow: hidden;
  }
`
export const Logo = styled.img`
  @media (max-width: 480px) {
    width: 250px;
  }
`
export const ResetPassText = styled(Link)`
  text-align: right;
  color: ${Color.Tertiary};
  &:hover {
    color: ${Color.Tertiary_Hover};
  }
  &:active,
  &:focus {
    color: ${Color.Tertiary_Pressed};
  }
`
