import React, { createContext, useState } from "react"

export const ShowNavBarContext = createContext()

export const ShowNavBarProvider = ({ children }) => {
  const [isShowNavBar, setIsShowNavBar] = useState(true)

  return (
    <ShowNavBarContext.Provider value={{ isShowNavBar, setIsShowNavBar }}>
      {children}
    </ShowNavBarContext.Provider>
  )
}