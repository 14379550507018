import { FontFormat, Color } from "./index"

const errorStyle = {
  ...FontFormat.Label_MD,
  color: Color.Error,
  textAlign: "left",
  position: "relative",
  top: "-15px",
  margin: "0px",
}

export const Validator = {
    errorStyle
}
