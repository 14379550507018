// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/IBMPlexSansThai-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400;500;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'IBM Plex Sans Thai', ;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: 500;
}

body {
  margin: 0;
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-style: normal;
}

textarea {
  margin: 0;
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-style: normal;
}

span,
a,
p,
b,
strong,
textarea,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.css-14lo706 {
  font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/style/main.css"],"names":[],"mappings":"AAEA;EACE,mCAAmC;EACnC,+DAAkE;EAClE,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,6CAA6C;EAC7C,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,6CAA6C;EAC7C,kBAAkB;AACpB;;AAEA;;;;;;;;;;;;EAYE,SAAS;AACX;AACA;EACE,0BAA0B;AAC5B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400;500;600;700&display=swap');\n\n@font-face {\n  font-family: 'IBM Plex Sans Thai', ;\n  src: url('../font/IBMPlexSansThai-Regular.ttf') format('truetype');\n  font-weight: 500;\n}\n\nbody {\n  margin: 0;\n  font-family: \"IBM Plex Sans Thai\", sans-serif;\n  font-style: normal;\n}\n\ntextarea {\n  margin: 0;\n  font-family: \"IBM Plex Sans Thai\", sans-serif;\n  font-style: normal;\n}\n\nspan,\na,\np,\nb,\nstrong,\ntextarea,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin: 0;\n}\n.css-14lo706 {\n  font-size: 14px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
